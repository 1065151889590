import React from "react";

type ServiceCardProps = {
  title: string;
  description: string;
  imageSrc: string;
  company: string;
};

class ServiceCard extends React.Component<ServiceCardProps> {
  
  render() {
    return (
      <section>
  <div className="container px-6 m-auto">
    <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
      <div className="col-span-4 lg:col-span-6 ">
      <div className="max-w-2xl rounded overflow-hidden shadow-2xl bg-white m-4 ">
        <img className="w-full" src={this.props.imageSrc} alt={this.props.title} />
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2 text-center">{this.props.title}</div>
          <p className="text-gray-700 text-base">
            {this.props.description}
          </p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {this.props.company}
          </span>
        </div>
      </div>
      </div>
      <div className="col-span-4 lg:col-span-6">
<ul className="divide-y divide-slate-100">
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Dashboard icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Mit unserem Umzugsservice können Sie den Stress eines Umzugs vergessen. Unser Team kümmert sich um alles, von der Verpackung bis zum Transport und Auspacken, sodass Sie sich entspannt zurücklehnen können.            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="User icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Unsere Experten verwenden hochwertige Materialien und Techniken, um Ihre Möbel und Wertgegenstände sicher zu verpacken, wodurch das Risiko von Beschädigungen minimiert wird.              </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Analytics icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Durch die Beauftragung unseres professionellen Umzugsteams sparen Sie wertvolle Zeit, die Sie für andere wichtige Aufgaben rund um Ihren Umzug nutzen können.              
            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Cloud icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Wir bieten umfassenden Versicherungsschutz für alle transportierten Gegenstände, um Ihnen zusätzliche Sicherheit und Ruhe zu gewährleisten.            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Dashboard icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Unser Team besteht aus erfahrenen und geschulten Profis, die wissen, wie man selbst die schwierigsten Umzugsprojekte effizient und sicher bewältigt.            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="User icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Wir bieten maßgeschneiderte Umzugslösungen, die genau auf Ihre Bedürfnisse und Anforderungen zugeschnitten sind, egal ob für den privaten oder gewerblichen Umzug.            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Analytics icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Unsere Zuverlässigkeit und Pünktlichkeit bei der Durchführung von Umzügen sorgt dafür, dass Ihr Umzug so reibungslos und stressfrei wie möglich verläuft.            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Money Bill Wave icon" role="graphics-symbol">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 9V7.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V9m21 0v6m0 0V16.5a3 3 0 00-3 3h-15a3 3 0 01-3-3V15m21 0H2.25M6.75 7.5v9M17.25 7.5v9" />
        </svg>

        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Wir bieten klare und transparente Preisgestaltung ohne versteckte Kosten, damit Sie genau wissen, was Sie erwartet.
                        </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 3c10 4 10 14 13 14s4-10 4-10-10-4-10-4-4 10-4 10S6 7 6 3z"/>
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 3c0 3.5 3.5 7.5 7 7.5"/>
</svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Wir setzen modernste Umzugstechnologien und -fahrzeuge ein, um die Sicherheit und Effizienz Ihres Umzugs zu gewährleisten.
            </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25M9 5.25V3m6 2.25V3m2.25 9.75h2.25m-13.5 0H4.5m15 0a7.5 7.5 0 11-15 0m15 0V9.75a9 9 0 10-18 0V15m0 0v1.5a3 3 0 003 3h1.5a3 3 0 003-3m-7.5 0a3 3 0 003-3m3 3v2.25"/>
</svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Wir setzen auf umweltfreundliche Praktiken bei unserem Umzugsservice, um den ökologischen Fußabdruck Ihres Umzugs zu minimieren.                        </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m2.25-10.5a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Unser engagiertes Kundenservice-Team steht Ihnen rund um die Uhr zur Verfügung, um Fragen zu beantworten und Unterstützung zu bieten.                        </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg 
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
            aria-label="Shield Check icon"
            role="graphics-symbol"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Wir gewährleisten eine schnelle und effiziente Abwicklung Ihres Umzugs, damit Sie sich schnell in Ihrem neuen Zuhause oder Büro einleben können.                        </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
            aria-label="Mail icon"
            role="graphics-symbol"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 2L2 7v10l10 5 10-5V7l-10-5z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Wir bieten eine umfassende Beratung und Planung im Vorfeld Ihres Umzugs, um sicherzustellen, dass alles nach Ihren Wünschen verläuft.                        </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg 
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
            aria-label="Mail icon"
            role="graphics-symbol"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 2L2 7v10l10 5 10-5V7l-10-5z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Unsere strengen Sicherheitsprotokolle während des Transports und der Handhabung Ihrer Gegenstände bieten Ihnen zusätzliche Beruhigung                        </p>
        </div>
    </li>
    <li className="flex items-start gap-4 px-4 py-3">
        <div className="flex items-center text-emerald-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Cloud icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
            </svg>
        </div>
        <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
            <p className="w-full text-sm truncate text-slate-500">
            Mit jahrelanger Erfahrung in der Umzugsbranche bieten wir Fachwissen und Zuverlässigkeit, auf die Sie sich verlassen können.                        </p>
        </div>
    </li>
</ul>
      </div>
    </div>
  </div>
</section>
   
    );
  }
}

type ServicesSectionProps = {
  services: ServiceCardProps[];
};

type ServicesSectionState = {
  currentIndex: number;
};

class ServicesSection extends React.Component<ServicesSectionProps, ServicesSectionState> {
  intervalId?: number;
  state = {
    currentIndex: 0,
  };

  componentDidMount() {
    this.startSlideshow();
  }

  componentWillUnmount() {
    this.stopSlideshow();
  }

  startSlideshow = () => {
    this.intervalId = window.setInterval(() => {
      this.setState(prevState => ({
        currentIndex: (prevState.currentIndex + 1) % this.props.services.length,
      }));
    }, 3000); // Slide every 3 seconds
  };

  stopSlideshow = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  render() {
    const { currentIndex } = this.state;
    const { services } = this.props;
    const service = services[currentIndex];

    return (
      <div className="flex flex-wrap justify-center items-center bg-white">
        <ServiceCard
          key={service.title}
          title={service.title}
          description={service.description}
          imageSrc={service.imageSrc}
          company={service.company}
        />
      </div>
    );
  }
}

export default ServicesSection;




