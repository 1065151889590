import * as React from 'react';
import CardPreise from '../../Section/Card/CardPreise';

export interface IPricesArrivalProps {
}

export interface IPricesArrivalState {
}

export default class PricesArrival extends React.Component<IPricesArrivalProps, IPricesArrivalState> {
  constructor(props: IPricesArrivalProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
<div className='container'>
        <section>
          <div className="container px-6 text-center">
            <h1 className='lg:text-2xl font-bold text-gray-800 text-center my-8'>AN & ABFAHRTSKOSTEN</h1>
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-3">
              <CardPreise
                  title="2 Mann inkl. 1 LKW"
                  price="1 STD. Anfahrt"
                  description="max. 2,4t Beladung bei 30m3"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-3">
              <CardPreise
                  title="Außerhalb Wien"
                  price="2 STD. Anfahrt"
                  description="Wien Umgebung"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-3">
              <CardPreise
                  title="KM Geld ohne Maut"
                  price="1,30€/KM"
                  description="Ab Stadtgerenze Wien. Gilt für den kleinen LKW!"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-3">
                <CardPreise
                  title="KM Geld inkl. Maut"
                  price="1,40€/KM"
                  description="Ab Stadtgrenze Wien. Gilt für alle LKWs ab 3,5t. Mautgebühren für Österreich & Deutschland. Weitere auf Anfrage!"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
            </div>
  
          </div>
        </section>
          
        </div>
    );
  }
}
