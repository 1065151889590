import * as React from "react";
import TitelCard from "../Section/Card/TitelCard";
import Banner from "../Section/Banner";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Make sure to import the CSS too
import SliderIndicatorsInside from "Elements/Section/SliderIndicatorsInside";


export interface IMoveFurnitureProps {}

export interface IMoveFurnitureState {}

export default class MoveFurniture extends React.Component<
  IMoveFurnitureProps,
  IMoveFurnitureState
> {
  constructor(props: IMoveFurnitureProps) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    AOS.init({
      // your settings here
      duration: 2000,
    });
  }
   imageUrls = [
    "https://Tailwindmix.b-cdn.net/image-04.jpg",
    "https://Tailwindmix.b-cdn.net/image-05.jpg",
    "https://Tailwindmix.b-cdn.net/image-05.jpg",
    "https://Tailwindmix.b-cdn.net/image-05.jpg",
    // Add more URLs as needed
  ];
  public render() {
    return (
      <div className="py-6"> 

        <div>
          <img
            src="../../../img/service/umzug/Movefurniture-1.jpg"
            alt="Möbel bewegen"
            className="w-full h-fit object-cover mt-24 mx-auto"
          />
        </div>
        <Banner isHome={false} pageName="Möbel bewegen" color="white"/>

        <div className="container shadow-lg p-3">
        <section>
        <div className="">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-6">
              <div className=" my-6" data-aos="fade-up" data-aos-duration="1000">
                <p className="text-left text-gray-700 my-2">
                    Ein Service, den einige von uns benötigen, wenn sie in eine neue
                    Residenz oder einen neuen Hauptsitz des Unternehmens umziehen
                    möchten. UMZUGSTRUPPE Firma bietet Möbelumzugsdienste für Häuser,
                    Hotels, Unternehmen, Institutionen und andere an …….
                  </p>
                  <p className="text-gray-700">
                    Möbelumzugsunternehmen schulen zunächst ein volles Team, um die
                    Möbel mit hochwertigen Materialien zu bedecken, die es ermöglichen,
                    die Möbel so lange zu konservieren, bis das Gepäck unversehrt das
                    Haus erreicht, und es dann in die ordnungsgemäß ausgestatteten Autos
                    des Unternehmens zu legen, die ihm das Reisen ermöglichen und
                    bewegen Sie sich dorthin, wo er keine Kratzer oder Brüche auf der
                    Straße hat, während Sie fahren und in Sicherheit ankommen, und geben
                    Sie dem Kunden alles, was sein Recht garantiert; Sich über die Möbel
                    zu beruhigen, die er bewegen möchte, und die vereinbarte Zeit
                    einzuhalten.
                  </p>
          </div>
        </div>
            <div className="col-span-4 lg:col-span-6">
              <img className="w-full h-auto" src="../../../img/service/umzug/Movefurniture-2.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>

        <div className="flex flex-wrap container shadow-lg p-3">
          {/* Your cards */}
          <TitelCard
            titel="Facharbeiter"
            description="Facharbeiter für die Demontage und Installation von Möbeln (Tischler, Elektriker, Techniker)."
            image="https://img.icons8.com/ios/50/000000/move-by-trolley.png"
          />
          <TitelCard
            titel="Transportwagen"
            description="Mehrere verschiedene Autos für den Transport ausgerüstet."
            image="https://img.icons8.com/ios/50/000000/move-by-trolley.png"
          />
          <TitelCard
            titel="Ordnen Sie die Möbel"
            description="Engagierte Mitarbeiter arrangieren Möbel, um die Teile des Autos optimal zu nutzen."
            image="https://img.icons8.com/ios/50/000000/move-by-trolley.png"
          />
          <TitelCard
            titel="Berufskraftfahrer"
            description="Fahrer mit einem hohen Maß an Erfahrung."
            image="https://img.icons8.com/ios/50/000000/move-by-trolley.png"
          />
          {/* End of your cards */}
        </div>
        <div>
            <hr />
        </div>
     {/*<!-- Component: Two columns even layout --> */}
     <section data-aos="fade-right container shadow-lg p-3" data-aos-duration="1000">
        <div className="container px-6 m-auto mt-6 relative">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-6 mt-6">
            <SliderIndicatorsInside images={this.imageUrls} />

            </div>
            <div className="col-span-4 lg:col-span-6">
                <h2 className="text-3xl font-bold text-gray-800 mb-4 mt-4">
                Bestimmen Sie den Preis
                </h2>
                <p className="text-gray-700 m-4">
                <strong>Die Transferpreise für Möbel variieren von Kunde zu Kunde wie folgt:</strong><br/> 
                Die Menge an Gepäck, die bewegt werden muss, denn je mehr Gepäckstücke, desto mehr Arbeiter und desto mehr Autos.
                Je weiter die Möbel bewegt werden sollen, desto höher ist der Preis, je weiter die Entfernung ist.
                Sie hängt von den Materialien ab, die zum Verpacken der Teile verwendet werden
                </p>
                <p className="text-gray-700 m-4">
                <strong>Die Transferpreise für Möbel variieren von Kunde zu Kunde wie folgt:</strong><br/> 
                Die Menge an Gepäck, die bewegt werden muss, denn je mehr Gepäckstücke, desto mehr Arbeiter und desto mehr Autos.
                Je weiter die Möbel bewegt werden sollen, desto höher ist der Preis, je weiter die Entfernung ist.
                Sie hängt von den Materialien ab, die zum Verpacken der Teile verwendet werden
                </p>
              
            </div>
         
          </div>
   
        </div>
      </section>
      {/*<!-- End Two columns even layout --> */}
        </div>
      </div>
    );
  }
}
