import Banner from "Elements/Section/Banner";
import * as React from "react";
import { NavLink } from "react-router-dom";

export interface IFreeOfferProps {}

export interface IFreeOfferState {
  Name: string;
  Email: string;
  Telefon: string;
  umzugNach: string;
  umzugVon: string;
  wohnungsBüroGrösse: string;
  personenarbeitsplätze: string;
  DateTime: string;
  SONSTIGES: string;
  UploadPhoto: string;
  Aufzüge: string;
  Parkplatz: string;
  Treppenhaus: string;
  Verpackung: string;
  Entsorgung: string;
  Möbelmontage: string;
  Möbelabholung: string;
  succ: boolean;
}

export default class FreeOffer extends React.Component<
  IFreeOfferProps,
  IFreeOfferState
> {
  constructor(props: IFreeOfferProps) {
    super(props);

    this.state = {
      Name: "",
      Email: "",
      Telefon: "",
      umzugNach: "",
      umzugVon: "",
      wohnungsBüroGrösse: "",
      personenarbeitsplätze: "",
      DateTime: new Date().toISOString().slice(0, 16),
      SONSTIGES: "",
      UploadPhoto: "",
      Aufzüge: "",
      Parkplatz: "",
      Treppenhaus: "",
      Verpackung: "",
      Entsorgung: "",
      Möbelmontage: "",
      Möbelabholung: "",
      succ: true,
    };
  }

  private handleSubmit = () => {
    const {
      Name,
      Email,
      Telefon,
      umzugNach,
      umzugVon,
      wohnungsBüroGrösse,
      personenarbeitsplätze,
      DateTime,
      SONSTIGES,
      Aufzüge,
      Parkplatz,
      Treppenhaus,
      Verpackung,
      Entsorgung,
      Möbelmontage,
      Möbelabholung,
    } = this.state;
    const data = {
      Name,
      Email,
      Telefon,
      umzugNach,
      umzugVon,
      wohnungsBüroGrösse,
      personenarbeitsplätze,
      DateTime,
      SONSTIGES,
      Aufzüge,
      Parkplatz,
      Treppenhaus,
      Verpackung,
      Entsorgung,
      Möbelmontage,
      Möbelabholung,
    };

    fetch("https://mjd.umzugstruppe.at/api/send_email.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
    this.setState({
      Name: "",
      Email: "",
      Telefon: "",
      umzugNach: "",
      umzugVon: "",
      wohnungsBüroGrösse: "",
      personenarbeitsplätze: "",
      DateTime: new Date().toISOString().slice(0, 16),
      SONSTIGES: "",
      UploadPhoto: "",
      Aufzüge: "",
      Parkplatz: "",
      Treppenhaus: "",
      Verpackung: "",
      Entsorgung: "",
      Möbelmontage: "",
      Möbelabholung: "",
      succ: false,
    });
  };

  public render() {
    return (
      <div className="py-10">
        <Banner isHome={false} pageName="Kostenloses Angebot" color="white" />
        <div className="container py-10 font-bold text-center">
          <h1>KOSTENLOSEN BESICHTIGUNG</h1>
          <p>
            Felder mit einem <span className="text-red-500">*</span> sind
            Pflichtfelder
          </p>
        </div>
        {this.state.succ === false ? (
          <div
            className="w-full container px-4 py-3 text-sm border rounded border-emerald-100 bg-emerald-50 text-emerald-500 mx-auto items-center justify-center flex flex-col"
            role="alert">
            <h3 className="mb-2 font-semibold">Vielen Dank</h3>
            <p>
              Vielen Dank für Ihre Anfrage. Wir werden uns in Kürze bei Ihnen
              melden.
            </p>
            <NavLink to="/home"
            className="mx-auto bg-red-500 text-white hover:bg-red-600 text-sm font-bold py-2 px-4 rounded mt-4 w-1/2 text-center">
            Home
            </NavLink>
          </div>
        ) : (
          <>
        <p className="container text-sm font-bold mb-10 text-center">
            FÜLLEN SIE DIE FELDER UNTEN AUS UND WIR ERSTELLEN IHNEN KOSTENLOS
            EIN KONKRETES ANGEBOT.
        </p>

            <div className="container shadow-lg py-3">
              <div className="container p-6 m-auto ">
                <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                  <div className="col-span-4 lg:col-span-6">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block text-sm m-3">
                        <span className="text-gray-700">
                          Name <span className="text-red-500">*</span>
                        </span>
                        <input
                          onChange={(e) =>
                            this.setState({ Name: e.target.value })
                          }
                          value={this.state.Name}
                          type="text"
                          placeholder="Name"
                          className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-2"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-span-4 lg:col-span-6">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block text-sm m-3">
                        <span className="text-gray-700">
                          Email <span className="text-red-500">*</span>
                        </span>
                        <input
                          onChange={(e) =>
                            this.setState({ Email: e.target.value })
                          }
                          value={this.state.Email}
                          type="text"
                          placeholder="Email"
                          className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-2"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container px-6 m-auto">
                <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                  <div className="col-span-4 md:col-span-8 lg:col-span-12">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block text-sm m-3">
                        <span className="text-gray-700">
                          Telefon <span className="text-red-500">*</span>
                        </span>
                        <input
                          onChange={(e) =>
                            this.setState({ Telefon: e.target.value })
                          }
                          value={this.state.Telefon}
                          type="text"
                          placeholder="Telefon"
                          className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-2"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container px-6 m-auto">
                <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                  <div className="col-span-4 lg:col-span-6">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block text-sm m-3">
                        <span className="text-gray-700">
                          Umzug nach <span className="text-red-500">*</span>
                        </span>
                        <input
                          onChange={(e) =>
                            this.setState({ umzugNach: e.target.value })
                          }
                          value={this.state.umzugNach}
                          type="text"
                          placeholder="wien 1010"
                          className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-2"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-span-4 lg:col-span-6">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block text-sm m-3">
                        <span className="text-gray-700">
                          Umzug von <span className="text-red-500">*</span>
                        </span>
                        <input
                          onChange={(e) =>
                            this.setState({ umzugVon: e.target.value })
                          }
                          value={this.state.umzugVon}
                          type="text"
                          placeholder="wien 1010"
                          className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-2"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container px-6 m-auto">
                <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                  <div className="col-span-4 lg:col-span-6">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block text-sm m-3">
                        <span className="text-gray-700">
                          Wohnungs-/Bürogrösse{" "}
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          onChange={(e) =>
                            this.setState({
                              wohnungsBüroGrösse: e.target.value,
                            })
                          }
                          value={this.state.wohnungsBüroGrösse}
                          type="text"
                          placeholder="10 m2"
                          className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-2"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-span-4 lg:col-span-6">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block text-sm m-3">
                        <span className="text-gray-700">
                          Personenarbeitsplätze{" "}
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          onChange={(e) =>
                            this.setState({
                              personenarbeitsplätze: e.target.value,
                            })
                          }
                          value={this.state.personenarbeitsplätze}
                          type="text"
                          placeholder="4"
                          className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-2"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container px-6 m-auto">
                <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                  <div className="col-span-4 md:col-span-8 lg:col-span-12">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block text-sm m-3">
                        <span className="text-gray-700">
                          Datum und Uhrzeit{" "}
                          <span className="text-red-500">*</span>
                        </span>
                        <input
                          value={this.state.DateTime}
                          onChange={(e) =>
                            this.setState({ DateTime: e.target.value })
                          }
                          type="datetime-local"
                          className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-2"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container px-6 m-auto">
                <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                  <div className="col-span-4 lg:col-span-2">
                    <div className="relative flex flex-wrap items-center">
                      <input
                        className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-red-500 checked:bg-red-500 checked:hover:border-red-600 checked:hover:bg-red-600 focus:outline-none checked:focus:border-red-700 checked:focus:bg-red-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                        type="checkbox"
                        checked={this.state.Aufzüge === "true" ? true : false}
                        onChange={(e) =>
                          this.setState({
                            Aufzüge: e.target.checked.toString(),
                          })
                        }
                        id="id-c01"
                      />
                      <label
                        className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                        htmlFor="id-c01"
                      >
                        Aufzüge
                      </label>
                    </div>
                  </div>
                  <div className="col-span-4 lg:col-span-2">
                    <div className="relative flex flex-wrap items-center">
                      <input
                        className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-red-500 checked:bg-red-500 checked:hover:border-red-600 checked:hover:bg-red-600 focus:outline-none checked:focus:border-red-700 checked:focus:bg-red-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                        type="checkbox"
                        checked={this.state.Parkplatz === "true" ? true : false}
                        onChange={(e) =>
                          this.setState({
                            Parkplatz: e.target.checked.toString(),
                          })
                        }
                        id="id-c02"
                      />
                      <label
                        className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                        htmlFor="id-c02"
                      >
                        Parkplatz
                      </label>
                      <svg
                        className="pointer-events-none absolute left-0 top-1 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        aria-labelledby="title-1 description-1"
                        role="graphics-symbol"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="col-span-4 lg:col-span-2">
                    <div className="relative flex flex-wrap items-center">
                      <input
                        className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-red-500 checked:bg-red-500 checked:hover:border-red-600 checked:hover:bg-red-600 focus:outline-none checked:focus:border-red-700 checked:focus:bg-red-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                        type="checkbox"
                        checked={
                          this.state.Treppenhaus === "true" ? true : false
                        }
                        onChange={(e) =>
                          this.setState({
                            Treppenhaus: e.target.checked.toString(),
                          })
                        }
                        id="id-c03"
                      />
                      <label
                        className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                        htmlFor="id-c03"
                      >
                        Treppenhaus
                      </label>
                      <svg
                        className="pointer-events-none absolute left-0 top-1 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        aria-labelledby="title-1 description-1"
                        role="graphics-symbol"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="col-span-4 lg:col-span-2">
                    <div className="relative flex flex-wrap items-center">
                      <input
                        className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-red-500 checked:bg-red-500 checked:hover:border-red-600 checked:hover:bg-red-600 focus:outline-none checked:focus:border-red-700 checked:focus:bg-red-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                        type="checkbox"
                        checked={
                          this.state.Verpackung === "true" ? true : false
                        }
                        onChange={(e) =>
                          this.setState({
                            Verpackung: e.target.checked.toString(),
                          })
                        }
                        id="id-c04"
                      />
                      <label
                        className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                        htmlFor="id-c04"
                      >
                        Verpackung
                      </label>
                      <svg
                        className="pointer-events-none absolute left-0 top-1 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        aria-labelledby="title-1 description-1"
                        role="graphics-symbol"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="col-span-4 lg:col-span-2">
                    <div className="relative flex flex-wrap items-center">
                      <input
                        className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-red-500 checked:bg-red-500 checked:hover:border-red-600 checked:hover:bg-red-600 focus:outline-none checked:focus:border-red-700 checked:focus:bg-red-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                        type="checkbox"
                        checked={
                          this.state.Entsorgung === "true" ? true : false
                        }
                        onChange={(e) =>
                          this.setState({
                            Entsorgung: e.target.checked.toString(),
                          })
                        }
                        id="id-c05"
                      />
                      <label
                        className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                        htmlFor="id-c05"
                      >
                        Entsorgung
                      </label>
                      <svg
                        className="pointer-events-none absolute left-0 top-1 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        aria-labelledby="title-1 description-1"
                        role="graphics-symbol"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="col-span-4 lg:col-span-2">
                    <div className="relative flex flex-wrap items-center">
                      <input
                        className="peer h-4 w-4 cursor-pointer appearance-none rounded border-2 border-slate-500 bg-white transition-colors checked:border-red-500 checked:bg-red-500 checked:hover:border-red-600 checked:hover:bg-red-600 focus:outline-none checked:focus:border-red-700 checked:focus:bg-red-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-100 disabled:bg-slate-50"
                        type="checkbox"
                        checked={
                          this.state.Möbelmontage === "true" ? true : false
                        }
                        onChange={(e) =>
                          this.setState({
                            Möbelmontage: e.target.checked.toString(),
                          })
                        }
                        id="id-c06"
                      />
                      <label
                        className="cursor-pointer pl-2 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:text-slate-400"
                        htmlFor="id-c06"
                      >
                        Möbelmontage
                      </label>
                      <svg
                        className="pointer-events-none absolute left-0 top-1 h-4 w-4 -rotate-90 fill-white stroke-white opacity-0 transition-all duration-300 peer-checked:rotate-0 peer-checked:opacity-100 peer-disabled:cursor-not-allowed"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        aria-labelledby="title-1 description-1"
                        role="graphics-symbol"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.8116 5.17568C12.9322 5.2882 13 5.44079 13 5.5999C13 5.759 12.9322 5.91159 12.8116 6.02412L7.66416 10.8243C7.5435 10.9368 7.37987 11 7.20925 11C7.03864 11 6.87501 10.9368 6.75435 10.8243L4.18062 8.42422C4.06341 8.31105 3.99856 8.15948 4.00002 8.00216C4.00149 7.84483 4.06916 7.69434 4.18846 7.58309C4.30775 7.47184 4.46913 7.40874 4.63784 7.40737C4.80655 7.406 4.96908 7.46648 5.09043 7.57578L7.20925 9.55167L11.9018 5.17568C12.0225 5.06319 12.1861 5 12.3567 5C12.5273 5 12.691 5.06319 12.8116 5.17568Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container px-6 m-auto">
                <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                  <div className="col-span-4 md:col-span-8 lg:col-span-12">
                    <div className="grid grid-cols-1 gap-6">
                      <label className="block text-sm m-3">
                        <span className="text-gray-700">SONSTIGES</span>
                        <textarea
                          onChange={(e) =>
                            this.setState({ SONSTIGES: e.target.value })
                          }
                          className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-2"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/**
*   <div className="container px-6 m-auto">
  <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
    <div className="col-span-4 md:col-span-8 lg:col-span-12">
    <div className="grid grid-cols-1 gap-6">
                <label className="block text-sm m-3">
                    <span className="text-gray-700">Upload Photo</span>
                    <input
                    onChange={e => this.setState({ UploadPhoto: e.target.value })}
                    value={this.state.UploadPhoto}
                     type="file" 
                     placeholder='Upload Photo'
                     className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-2"/>                              
                </label>
                </div>
    </div>
  </div>

</div>
*/}
              <div className="container px-6 m-auto">
                <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                  <div className="col-span-4 md:col-span-8 lg:col-span-12">
                    <div className="grid grid-cols-1 gap-6">
                      <button
                        disabled={
                          !this.state.Name ||
                          !this.state.Email ||
                          !this.state.Telefon ||
                          !this.state.umzugNach ||
                          !this.state.umzugVon ||
                          !this.state.wohnungsBüroGrösse ||
                          !this.state.personenarbeitsplätze ||
                          !this.state.DateTime
                        }
                        onClick={this.handleSubmit}
                        className="w-full mt-1 text-base shadow-sm focus:outline-none outline-none p-3 bg-red-500 text-white
                disabled:cursor-not-allowed disabled:bg-slate-100 disabled:text-slate-400"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
