import * as React from 'react';
import MoveFurniture from '../../Services/MoveFurniture';
import PricesMoving from '../../Pages/Prices/PricesMoving';
import PricesArrival from '../../Pages/Prices/PricesArrival';
import PricesParking from '../../Pages/Prices/PricesParking';
import PreiseMovingBoxes from '../../Pages/Prices/PreiseMovingBoxes';
import PreisePackingMaterials from '../../Pages/Prices/PreisePackingMaterials';
import PreisePrivatesTransportmittel from '../../Pages/Prices/PreisePrivatesTransportmittel';
import PreiseDisposal from '../../Pages/Prices/PreiseDisposal';

export interface IChildTabProps {
    activeTab:number
}

export interface IChildTabState {
    selectedCheckboxes: string[];
}

export default class ChildTab extends React.Component<IChildTabProps, IChildTabState> {
  constructor(props: IChildTabProps) {
    super(props);

    this.state = {
        selectedCheckboxes: [],

    }
  }
  handleCheckboxChange = (value:string ) =>  {
    // Toggle the selected checkbox
    const { selectedCheckboxes } = this.state;
    const selectedIndex = selectedCheckboxes.indexOf(value);
    let newSelectedCheckboxes: string[] = [];
    if (!selectedCheckboxes[selectedIndex])
    {
      newSelectedCheckboxes = newSelectedCheckboxes.concat(selectedCheckboxes, value);
    }
    else
    {
      newSelectedCheckboxes = selectedCheckboxes.filter((item) => item !== value);
    }
    this.setState({ selectedCheckboxes: newSelectedCheckboxes });
    console.log(this.state.selectedCheckboxes)
    }
  

  public render() {
    const { activeTab } = this.props;

    return (

      <div className='select-none'>
        <h2 className='text-xl font-bold text-center text-gray-800 m-auto mt-10'>* alle Preise inkl. 20% MwSt  </h2>
              <div className="p-4 mt-6 items-center justify-center m-auto">
        {activeTab === 1 && 
       <div>
        <PricesMoving/>
       </div>}
        {activeTab === 2 && <div>
          <PricesArrival/>
          </div>}
        {activeTab === 3 && <div>
          <PricesParking/>
          </div>}
        {activeTab === 4 && <div>
          <PreiseMovingBoxes/>
          </div>}
        {activeTab === 5 && <div>
          <PreisePackingMaterials/>
          </div>}
        {activeTab === 6 && <div>
          <PreisePrivatesTransportmittel/>
          </div>}
        {activeTab === 7 && <div>
          <PreiseDisposal/>
          </div>}


      </div>

      </div>
    );
  }
}
