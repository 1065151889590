import React from 'react';

interface CarouselState {
  activeIndex: number;
}

interface CarouselProps {
  images: string[]; // Array of image URLs
}

class CarouselIndicatorsControlsInside extends React.Component<CarouselProps, CarouselState> {
  state: CarouselState = {
    activeIndex: 0,
  };

  componentDidMount() {
    setInterval(() => {
      this.setState(prevState => ({
        activeIndex: (prevState.activeIndex + 1) % this.props.images.length,
      }));
    }, 1000); // Change image every second
  }

  render() {
    const { images } = this.props;
    const { activeIndex } = this.state;

    return (
      <div className="flex overflow-hidden">
        {images.map((image, index) => (
          <img
          style={{
            opacity: index === activeIndex ? 1 : 0,
            transition: 'opacity 1.5s ease-in-out',
          }}
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className={`w-full object-cover ${index === activeIndex ? 'block' : 'hidden'} lg:block lg:w-1/3`}
          />
        ))}
      </div>
    );
  }
}

export default CarouselIndicatorsControlsInside;
