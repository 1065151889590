import * as React from 'react';
import CardPreise from '../../Section/Card/CardPreise';

export interface IPricesParkingProps {
}

export interface IPricesParkingState {
}

export default class PricesParking extends React.Component<IPricesParkingProps, IPricesParkingState> {
  constructor(props: IPricesParkingProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
<div className='container'>
        <section>
          <div className="container px-6 text-center">
            <h1 className='lg:text-2xl font-bold text-gray-800 text-center my-8'>AN & ABFAHRTSKOSTEN</h1>
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="Innerhalb Wien"
                  price="150€"
                  description="eine Adresse"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="Innerhalb Wien"
                  price="250€"
                  description="Zwei Adressen"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="Außerhalb Wien"
                  price="Auf Anfrage"
                  description="Österreich & Europaweit"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
            </div>
  
          </div>
        </section>
          
        </div>
    );
  }
}
