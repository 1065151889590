import * as React from 'react';
import Banner from '../Section/Banner';
import CardPreise from '../Section/Card/CardPreise';
import TabsParent from '../Section/Tabs/TabsParent';

export interface IPricesProps {
}

export interface IPricesState {
}

export default class Prices extends React.Component<IPricesProps, IPricesState> {
  constructor(props: IPricesProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className=''>
      <Banner isHome={false} pageName="Preise & Umzugsservice" color="white"/> 
     <TabsParent/>

        
      </div>
    );
  }
}
