import * as React from 'react';
import { NavLink } from "react-router-dom";


export interface IHeaderProps {
}

export interface IHeaderState {
    isTop: boolean;
    isNavbarTogglerActive: boolean;
    subMenuActive: boolean;
}

export default class Header extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);

    this.state = {
      isTop: true,
      isNavbarTogglerActive: false,
      subMenuActive: false,


    }
   // Bind methods to the component instance
   this.handleNavbarToggle = this.handleNavbarToggle.bind(this);
   this.handleNavItemClick = this.handleNavItemClick.bind(this);
   this.handleSubmenuClick = this.handleSubmenuClick.bind(this);
   this.handleDocumentClick = this.handleDocumentClick.bind(this);

   this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // Add scroll event listener when the component mounts
    window.addEventListener('scroll', this.handleScroll);
    // Add click event listener to close the menu when clicking outside of it
    document.addEventListener('click', this.handleDocumentClick);
  }
  
  componentWillUnmount() {
    // Remove scroll and click event listeners when the component is about to unmount
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('click', this.handleDocumentClick);
  }
  handleNavbarToggle() {
    this.setState((prevState) => ({
      isNavbarTogglerActive: !prevState.isNavbarTogglerActive,
    }));
  }

  handleNavItemClick() {
    this.setState({
      isNavbarTogglerActive: false,
    });
  }

  handleSubmenuClick(event: any) {
    event.preventDefault();
    this.setState((prevState) => ({
      subMenuActive: !prevState.subMenuActive,
    }));
  }

  handleScroll() {
    const scrollPosition = window.scrollY;
    this.setState({ isTop: scrollPosition === 0 });
  }

  handleDocumentClick(event: MouseEvent) {
    const navbar = document.getElementById('navbarCollapse');
    const navbarToggler = document.getElementById('navbarToggler');
  
    // If the click is outside the navbar and the navbar toggler is not clicked
    if (navbar && navbarToggler && !navbar.contains(event.target as Node) && !navbarToggler.contains(event.target as Node)) {
      this.setState({
        isNavbarTogglerActive: false,
        subMenuActive: false,
      });
    }
  }

  public render(): JSX.Element {
    const { isTop } = this.state;

    return (
<div>
    {/* <!-- ====== Navbar Section Start --> */}
    <div className={`ud-header ${isTop ? ' absolute ' : ' sticky '} top-0 left-0 z-40 flex w-full items-center bg-transparent wow fadeIn`} data-wow-duration="2s" data-wow-delay="0.5s">
    <div className="container">
      <div className="relative -mx-4 flex items-center justify-between">
      <NavLink to="/home">

        <div className="w-60 max-w-full px-4">
          <button className="navbar-logo block w-full py-4">
            {isTop ? (
                <img src="../../../img/logo/umzugstruppe-logo.png" alt="logo" className="lg:w-32 w-32 " />
                ) : (
                <img src="../../../img/logo/umzugstruppe-logo.png" alt="logo2" className="lg:w-32 w-32 " />
                )}

          </button>
        </div>
        </NavLink>
        <div className="flex w-full items-center justify-between px-4">
          <div>
            <button onClick={this.handleNavbarToggle} id="navbarToggler"
              className="absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-red-500 focus:ring-2 lg:hidden">
              <span className="relative my-[6px] block h-[2px] w-[30px] bg-black"></span>
              <span className="relative my-[6px] block h-[2px] w-[30px] bg-black"></span>
              <span className="relative my-[6px] block h-[2px] w-[30px] bg-black"></span>
            </button>
            <nav id="navbarCollapse"
              className={`absolute right-4 top-full ${this.state.isNavbarTogglerActive?"":"hidden"} w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:py-0 lg:px-4 lg:shadow-none xl:px-6`}>
              <ul className="blcok lg:flex 2xl:ml-20">
                <li className="group relative">
                <NavLink to="/home">

                  <button
                  onClick={this.handleNavItemClick} 
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-red-500 lg:mr-0 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70">
                    Home
                    </button>
                </NavLink>
                </li>
                <li className="group relative">
                <NavLink to="/aboutus">

                  <button
                  onClick={this.handleNavItemClick}
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-red-500 lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-10">
                    Über uns
                  </button>
                  </NavLink>
                </li>
                <li className="group relative">
                <NavLink to="/Prices">

                  <button
                  onClick={this.handleNavItemClick}
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-red-500 lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-10">
                    Prices
                  </button>
                  </NavLink>
                </li>
                <li className="group relative">
               {/**
                *  <NavLink to="/pricing">
                  <button
                  onClick={this.handleNavItemClick}
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-red-500 lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-10">
                    Pricing
                  </button>
                  </NavLink>
                */}
                </li>
                <li onClick={this.handleSubmenuClick} className="submenu-item group relative">
                  <button 
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-red-500 lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-10">
                    Services

                    <svg  className="fill-current ml-2" width="16" height="20" viewBox="0 0 16 20" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.99999 14.9C7.84999 14.9 7.72499 14.85 7.59999 14.75L1.84999 9.10005C1.62499 8.87505 1.62499 8.52505 1.84999 8.30005C2.07499 8.07505 2.42499 8.07505 2.64999 8.30005L7.99999 13.525L13.35 8.25005C13.575 8.02505 13.925 8.02505 14.15 8.25005C14.375 8.47505 14.375 8.82505 14.15 9.05005L8.39999 14.7C8.27499 14.825 8.14999 14.9 7.99999 14.9Z" />
                    </svg>
                  </button>
                  <div className={`submenu relative top-full left-0 ${this.state.subMenuActive?"":"hidden"} w-[250px] rounded-sm bg-white p-4 transition-[top] duration-300 group-hover:opacity-100 lg:invisible lg:absolute lg:top-[110%] lg:block lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full`}>
                    <NavLink to="/MoveFurniture"
                      className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-red-500">
                     Möbel bewegen
                    </NavLink>
                    <NavLink to="/FurniturePacking"
                      className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-red-500">
                     Möbelverpackungsservice
                    </NavLink>
                    <NavLink to="/MovingOfficeFurniture"
                      className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-red-500">
                     Umzug von Büromöbeln
                    </NavLink>
                    <NavLink to="/OldFurniture"
                      className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-red-500">
                     Alte Möbel loswerden
                    </NavLink>
                    <NavLink to="/PianoTransport"
                      className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-red-500">
                     PianoTransport
                    </NavLink>
                    <NavLink to="/RemovingInstallingFurniture"
                      className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-red-500">
                     Möbel Aus- Und Einbauen
                    </NavLink>

                  </div>
                </li>
                <li className="group relative">
                <NavLink to="/contact">
                  <button
                  onClick={this.handleNavItemClick}
                    className="ud-menu-scroll mx-8 flex py-2 font-medium text-base text-dark group-hover:text-red-500 lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-10">
                    Contact
                  </button>
                  </NavLink>
                </li>

              </ul>
            </nav>
          </div>
        <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
       
            <NavLink to="/FreeOffer">
            <button
            onClick={this.handleNavItemClick} 
            className="signUpBtn rounded-md bg-red-600 bg-opacity-100 py-2 px-6 text-base font-medium text-white duration-300 ease-in-out hover:bg-red-800 hover:text-white">
                          Kostenloses Angebot

              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </div>
{ /* <!-- ====== Navbar Section End --> */}


</div>
    );
  }
}