import * as React from 'react';
import Banner from '../Section/Banner';
import TitelCard from '../Section/Card/TitelCard';

export interface IPianoTransportProps {
}

export interface IPianoTransportState {
}

export default class PianoTransport extends React.Component<IPianoTransportProps, IPianoTransportState> {
  constructor(props: IPianoTransportProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
        <div >
              <img
            src="../../../img/service/h-8.jpg"
            alt="Möbel bewegen"
            className="w-full h-fit object-cover mt-24 mx-auto"
            />
        <Banner isHome={false} pageName="PianoTransport" color="white"/>

        <div className='container shadow-lg p-3'>
        <h2 className='lg:text-2xl font-bold text-gray-800 text-left my-8'>SPEZIAL­TRANSPORTE</h2>
        <p className="text-left text-gray-700 my-4">
        Bei einem Umzug müssen manchmal richtig schwere Gegenstände transportiert werden und dann kann es mitunter auch gefährlich werden. Gefährlich für die am Umzug beteiligten Personen, aber auch für Wohnräume und Umzugsgüter. Nicht selten sind schwere Schäden das Resultat, wenn sperrige Umzugsgüter transportiert werden müssen. Damit dies nicht passiert, gibt es eine einfache Lösung: Die Profis für Spezialtransporte von den Möbelpackern!
        </p>
        </div>

        
        <div className='container shadow-lg p-3'>
            <p className="text-left text-gray-700 my-4">
            Wir haben immer wieder Musiker und Musikerinnen unter unseren Kunden. Wo Gitarren bei einem Umzug keine Probleme bereiten, sind Klaviere und Flügel schwieriger. Dennoch kein Grund zum Verzweifeln, denn die Umzuger sind zur Stelle. Aufgrund unserer jahrelangen Erfahrung wissen wir genau was bei einem Klaviertransport in Wien zu beachten ist und wie richtig abgesichert werden muss, damit das geliebte Musikinstrument nicht beschädigt, zerkratzt oder verstimmt wird.
            </p>
        </div>
        <div className="flex flex-wrap  container shadow-lg p-3">
          {/* Your cards */}
          <TitelCard
            titel="DIE EXPERTEN FÜR IHREN KLAVIERTRANSPORT"
            description="Selbst anspruchsvolle Transportaufgaben, wie zum Beispiel der Ausbau der Klaviatur oder der Pedale bei Ihrem Klavier, werden durch unser vielseitig geschultes Fachpersonal gelöst."
            image="https://img.icons8.com/ios/50/000000/move-by-trolley.png"
          />
          <TitelCard
            titel="Klaviertransport Wien"
            description="Der Transport Ihres Klaviers beim Umzug – sicher, professionell und zuverlässig"
            image="https://img.icons8.com/ios/50/000000/move-by-trolley.png"
          />
          <TitelCard
            titel="Koordination Ihres Umzugs von A bis Z
            "
            description=""
            image="https://img.icons8.com/ios/50/000000/move-by-trolley.png"
          />
          <TitelCard
            titel="Transparente und attraktive Preise"
            description=""
            image="https://img.icons8.com/ios/50/000000/move-by-trolley.png"
          />
          {/* End of your cards */}
        </div>
        <section className='container shadow-lg p-3 my-3'>
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-3">
            <img
            src="../../../img/service/umzug/PianoTransport-2.jpg"
            alt="Möbel bewegen"
            className="w-1/2 mb-4"
        />    
            </div>
            <div className="col-span-4 lg:col-span-6">
                <h2 className='lg:text-2xl font-bold text-gray-800 text-left my-8'>Kostenlose Beratung für Ihren Umzug</h2>
                <p className="text-left text-gray-700 my-4">
                Sie wünschen nähere Informationen? Dann rufen Sie uns an oder schicken Sie uns eine E-Mail – wir freuen uns auf Sie und Ihr Anliegen!
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3"></div>
          </div>
      </section>
      </div>
    );
  }
}
