import * as React from 'react';
import Banner from '../Section/Banner';

export interface IRemovingInstallingFurnitureProps {
}

export interface IRemovingInstallingFurnitureState {
}

export default class RemovingInstallingFurniture extends React.Component<IRemovingInstallingFurnitureProps, IRemovingInstallingFurnitureState> {
  constructor(props: IRemovingInstallingFurnitureProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
    <div className='py-6'>
        <img
            src="../../../img/service/h-9.png"
            alt="Möbel bewegen"
            className="w-full h-fit object-cover mt-24 mx-auto"
            />
            <Banner isHome={false} pageName="Möbel Aus- Und Einbauen" color="white"/>

        <div className='container p-3 shadow-2xl'>
            <h2 className='lg:text-2xl font-bold text-gray-800 text-left my-8'>Möbel aus- und einbauen</h2>
            <p className="text-left text-gray-700 my-4">
                Der Service für die Demontage und Installation von Möbeln ist ein Service, den wir durch Spezialisten und Techniker durchführen, die an der Demontage und Installation von Gepäck aller Art arbeiten, um die Bewegung beim Transport durch von uns arbeitende Techniker und Spezialisten zu erleichtern
                Die Umzugstruppe Furniture Moving Company verfügt über langjährige Erfahrung im Umzug von Möbeln, mit denen alle Möbelumzugsunternehmen konkurrieren. Wir gelten als eines der besten Möbelumzugsunternehmen, das sich auf den Umzug von Möbeln spezialisiert hat, und wir haben die besten Techniker und Spezialisten, die sich auf den Umzug von Möbeln spezialisiert haben
            </p>
        </div>
        <section className='container p-3 shadow-2xl'>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-3"></div>
            <div className="col-span-4 lg:col-span-3">
                <p
                    className="text-gray-700 my-4 mx-3 font-bold">
                    Entfernen Sie die Möbel
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3">
                <p
                    className="text-gray-700 my-4 mx-3 font-bold">
                    Möbelinstallation
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3"></div>
          </div>
        </div>
      </section>
        <section className='container p-3 shadow-2xl'>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-6">
                <p
                    className="text-gray-700 my-4 mx-3 container p-3 shadow-2xl">
                    Im Allgemeinen benötigen wir beim Bewegen von Möbeln besondere Sorgfalt und Aufmerksamkeit, damit beim Zerlegen und Installieren von Möbeln nichts Unerwünschtes auftritt, wie z. B. Beschädigungen oder unerwartete Verluste wie das Herunterfallen eines der Teile beim Bewegen, wodurch es ausgesetzt wird Schnelle Beschädigung oder Zerstörung, so Umgang mit Möbeln während des Transports Es bedarf einer besonderen und sorgfältigen Behandlung. Nicht nur die Übertragung, sondern auch der Rest der Vorgänge, die zum Abschluss des Übertragungsprozesses beitragen, da sich der Übertragungsprozess nicht nur im Transportschritt befindet, sondern aufeinanderfolgende Phasen sind, die mit einer gewissen Effizienz durchgeführt werden müssen, um dies sicherzustellen Der Übertragungsprozess wird für den Kunden in zufriedenstellender Weise abgeschlossen, da bei einer Verletzung einer der Stufen am Ende des Übertragungsprozesses ein Fehler in den üblichen Schritten auftreten kann</p>
            </div>
            <div className="col-span-4 lg:col-span-6">
                <p
                    className="text-gray-700 my-4 mx-3 container p-3 shadow-2xl">
                    Der Transfervorgang beginnt mit der Demontage der Möbel
                    Es ist bekannt, dass der Transportprozess zunächst mit der Demontage der Möbel selbst beginnt. Dabei werden die Möbelstücke professionell voneinander entfernt, um sicherzustellen, dass die Möbel nicht beschädigt werden, da die falsche Backe zum Bruch führen kann Zum Beispiel oder eine andere Fehlfunktion, so dass niemand diesen Schritt ausführen kann, da er geschulte Techniker und Experten in diesem Prozess benötigt, weil sie Möbelstücke mit äußerster Effizienz trennen können, um die Sicherheit der Möbel für die Kunden zu gewährleisten, und wenn die Der Prozess der Demontage der Möbel erfolgt ordnungsgemäß, dann können wir den Erfolg der restlichen nächsten Schritte garantieren.
                </p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative py-20 container p-3 shadow-2xl">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-[80px]"
            
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="../../../img/service/h-10.jpg"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-red-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-red-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">Wachsendes Unternehmen</h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                  Unser wachsendes Unternehmen zeichnet sich durch Innovation, Kundenzentriertheit, Expertise, Nachhaltigkeit und globale Präsenz aus.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Innovative Technologien
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                              Kundenzentrierter Ansatz
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Expertise im Fokus</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      <div className='container p-3 shadow-2xl'>
        <h2 className='lg:text-2xl font-bold text-gray-800 text-left my-8'>Die folgenden Schritte der Demontage der Möbel</h2>
        <ul>
            <li className="text-gray-700 m-4"><i className="cmt-skincolor fa fa-check-square mr-3"></i>Verkapselung</li>
            <li className="text-gray-700 m-4"><i className="cmt-skincolor fa fa-check-square mr-3"></i>Transport</li>
            <li className="text-gray-700 m-4"><i className="cmt-skincolor fa fa-check-square mr-3"></i>Installation</li>
        </ul>
      </div>
    </div>
    );
  }
}
