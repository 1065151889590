import * as React from 'react';
import CardPreise from '../../Section/Card/CardPreise';

export interface IPreiseMovingBoxesProps {
}

export interface IPreiseMovingBoxesState {
}

export default class PreiseMovingBoxes extends React.Component<IPreiseMovingBoxesProps, IPreiseMovingBoxesState> {
  constructor(props: IPreiseMovingBoxesProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (

<div className='container'>
        <section>
          <div className="container px-6 text-center">
          <p className='text-gray-600 text-center text-sm mb-8'>WIR NEHMEN AUSSCHLIESSLICH UNBESCHÄDIGTE KARTONS ZURÜCK. BENUTZTEN SIE AUSSCHLIESSLICH TRANSPARENTES KLEBEBAND, DA SONST KEINE ANNAHME DER LEIHKARTONS GEWÄHRLEISTET WIRD! FLATSCREENBOX WIRD NICHT IM VORFELD VERLEIHEN SONDERN WIRD ZUM TERMIN AM UMZUGSTAG VON UNSEREM TEAM MITGEBRACHT UND SPÄTER WIEDER MITGENOMME</p>

            <h1 className='lg:text-2xl font-bold text-gray-800 text-center my-8'>UMZUGKARTONS</h1>
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="UMZUG­KARTONS"
                  price="2.4 €"
                  description="Größe: 580x350x340 mm Max. Befüllung: 20 Kg nach unversehrter Rückgabe erhalten Sie 1€ Retour"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="KLEIDER­KARTONS"
                  price="16€"
                  description="Größe: 1300x560x550 mm Max. Befüllung: 1,00 lfm Hängegarderobe nach unversehrter Rückgabe erhalten Sie 6€ Retour"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="FLATSCREEN­BOX"
                  price="Gratis"
                  description="Max. Befüllung: bis zu 50 Bildschirmdiagonale nach unversehrter Rückgabe erhalten Sie 6€ Retour"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
            </div>
  
          </div>
        </section>
          
        </div>
    );
  }
}
