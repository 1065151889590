import * as React from 'react';
import CardPreise from '../../Section/Card/CardPreise';

export interface IPricesMovingProps {
}

export interface IPricesMovingState {
}

export default class PricesMoving extends React.Component<IPricesMovingProps, IPricesMovingState> {
  constructor(props: IPricesMovingProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
        <div className='container'>
        <section>
          <div className="container px-6 text-center">
            <h1 className='lg:text-2xl font-bold text-gray-800 text-center my-8'>ÜBERSIEDLUNGEN & UMZUGSHELFER</h1>
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12 m-6">
              <div className="col-span-4 lg:col-span-4">
                <CardPreise
                  title="2 Mann inkl. 1 LKW"
                  price="40€/h"
                  description="max. 1t Beladung bei 23m3"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="2 Mann inkl. 1 LKW"
                  price="55€/h"
                  description="max. 2,4t Beladung bei 30m3"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="Je weiteren LKW"
                  price="17€/h"
                  description="max. 1t Beladung bei 23m3"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
            </div>
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-6">
              <CardPreise
                  title="Je weiteren LKW"
                  price="25€/h"
                  description="max. 2,4t Beladung bei 30m3"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-6">
              <CardPreise
                  title="Je weiteren Umzugshelfer"
                  price="23€/h"
                  description="max. 1t Beladung bei 23m3"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
            </div>
          </div>
        </section>
          
        </div>
    );
  }
}
