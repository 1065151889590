import * as React from 'react';
import { NavLink } from 'react-router-dom';


export interface ICardPreiseProps {
    title: string;
    price: string;
    description: string;
    img: string;
}

export interface ICardPreiseState {
}

export default class CardPreise extends React.Component<ICardPreiseProps, ICardPreiseState> {
  constructor(props: ICardPreiseProps) {
    super(props);

    this.state = {
    }
    this.handleNavItemClick = this.handleNavItemClick.bind(this);

  }
  handleNavItemClick() {
    this.setState({
      isNavbarTogglerActive: false,
    });
  }

  public render() {
    return (
      <div>
              {/*<!-- Component: E-commerce card --> */}
      <div className="overflow-hidden rounded bg-gray-50 text-slate-500 shadow-lg shadow-slate-900  ">
        {/*  <!-- add this ../../../img/service/umzug/faceLogo.svg --> */}
      
        {/*  <!-- Body--> */}
        <div className="p-1 h-full">
          <header className="mt-10">
            <h3 className="text-xl font-medium text-slate-700">
             {this.props.title}
            </h3>
            <p className=" text-slate-400">{this.props.price}</p>
          </header>
          <p
            className="text-slate-500
            overflow-hidden
            overflow-ellipsis
            line-clamp-5
            h-32
            "
          >
            {this.props.description}
          </p>
        </div>
        {/*  <!-- Action base sized basic button --> */}
        <div className="flex justify-center p-6 pt-0">
       <NavLink to="/FreeOffer">
       <button
       onClick={this.handleNavItemClick} 
       className="inline-flex h-10 w-full items-center justify-center gap-2 whitespace-nowrap rounded bg-red-700 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-red-500 focus:bg-red-800 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">                     Kostenloses Angebot

         </button>
       </NavLink>
     </div>
      </div>
      {/*<!-- End E-commerce card --> */}
      </div>
    );
  }
}
