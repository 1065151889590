import * as React from 'react';
import CardPreise from '../../Section/Card/CardPreise';

export interface IPreisePackingMaterialsProps {
}

export interface IPreisePackingMaterialsState {
}

export default class PreisePackingMaterials extends React.Component<IPreisePackingMaterialsProps, IPreisePackingMaterialsState> {
  constructor(props: IPreisePackingMaterialsProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
<div className='container'>
        <section>
          <div className="container px-6 text-center">
            <h1 className='lg:text-2xl font-bold text-gray-800 text-center my-8'>VERPACKUNGSMATERIAL</h1>
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-3">
              <CardPreise
                  title="STRECHFOLIE"
                  price="34€"
                  description="je Rolle (270 lfm)"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-3">
              <CardPreise
                  title="LUFT­POLSTERFOLIE"
                  price="1.50€"
                  description="je Laufmeter (1m)"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-3">
              <CardPreise
                  title="SEIDENPAPIER"
                  price="10€"
                  description="je Kilogramm (1kg)"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-3">
                <CardPreise
                  title="KLEBEBAND"
                  price="3.5€"
                  description="Je Rolle (Beim Kauf von ab 25 Kartons, gratis) Größe: 1300x560x550 mm Max. Befüllung: 1,00 lfm Hängegarderobe"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
            </div>
  
          </div>
        </section>
          
        </div>
    );
  }
}
