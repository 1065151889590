import * as React from 'react';
import ServicesList from '../Section/ServicesList';
import Banner from '../Section/Banner';
import RequestForm from '../Section/RequestForm';

export interface IContactProps {
}

export interface IContactState {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  message: string;    
}

export default class Contact extends React.Component<IContactProps, IContactState> {
  constructor(props: IContactProps) {
    super(props);

    this.state = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        message: '',
      };
  }

 
  handleSubmit = (e:
    React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // Send form data to server
  };


    
  

    

  render() {
    return (
      <div>
     <Banner isHome={false} 
      pageName="Kontakt"
      color="violet"
      />
      <RequestForm />
      <div className='
      flex flex-col items-center justify-center
      w-full px-4 py-8 mx-auto
      md:px-10 md:py-16
      lg:px-12 lg:py-20
      xl:px-16 xl:py-24
      2xl:px-20 2xl:py-28
      
      '>
              {/*<!-- Component: Basic accordion --> */}
      <section className="w-full divide-y rounded divide-slate-300 ">
        <details className="p-4 group" open>
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
          Wo sind wir?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac01 desc-ac01"
            >
              <title id="title-ac01">Open icon</title>
              <desc id="desc-ac01">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500">
          umzugstruppe! ECHTE LÖSUNG, WIRKLICH SCHNELL!
          </p>
        </details>
        <details className="p-4 group" open>
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
          Wie kann ich einen Termin vereinbaren?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac02 desc-ac02"
            >
              <title id="title-ac02">Open icon</title>
              <desc id="desc-ac02">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500">
          Sie können uns jederzeit unter der Nummer <strong>+436763330600</strong> erreichen, um einen Termin für Ihren Möbeltransport zu vereinbaren.
          </p>
        </details>
        <details className="p-4 group" open>
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
          Wie lange dauert der Transport?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac03 desc-ac03"
            >
              <title id="title-ac03">Open icon</title>
              <desc id="desc-ac03">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500">
          Der Transport dauert in der Regel 1-2 Tage, abhängig von der Entfernung und der Menge der zu transportierenden Möbel.
          </p>
        </details>
        <details className="p-4 group" open>
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
          Wie viel kostet der Transport?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac04 desc-ac04"
            >
              <title id="title-ac04">Open icon</title>
              <desc id="desc-ac04">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500">
          Die Kosten variieren je nach Entfernung, Menge und Art der zu transportierenden Möbel. Kontaktieren Sie uns für ein maßgeschneidertes Angebot.
          </p>
        </details>
        <details className="p-4 group" open>
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
          Wie kann ich bezahlen?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac05 desc-ac05"
            >
              <title id="title-ac05">Open icon</title>
              <desc id="desc-ac05">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500">
          Sie können bar, per Überweisung oder mit Kreditkarte bezahlen, um die Kosten für Ihren Möbeltransport zu begleichen.
          </p>
        </details>
        <details className="p-4 group" open>
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
          Wie lange habe ich Garantie?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac06 desc-ac06"
            >
              <title id="title-ac06">Open icon</title>
              <desc id="desc-ac06">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500">
          Wir bieten Ihnen 3 Monate Garantie auf unseren Möbeltransport-Service, um sicherzustellen, dass Sie mit unserem Service zufrieden sind.
          </p>
        </details>
        <details className="p-4 group" open>
          <summary className="relative cursor-pointer list-none pr-8 font-medium text-slate-700 transition-colors duration-300 focus-visible:outline-none group-hover:text-slate-900  [&::-webkit-details-marker]:hidden">
          Wie kann ich meine Bestellung stornieren?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-0 w-4 h-4 transition duration-300 top-1 shrink-0 stroke-slate-700 group-open:rotate-45"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1.5"
              aria-labelledby="title-ac07 desc-ac07"
            >
              <title id="title-ac07">Open icon</title>
              <desc id="desc-ac07">
                icon that represents the state of the summary
              </desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </summary>
          <p className="mt-4 text-slate-500">
          Sie können Ihre Bestellung bis zu 24 Stunden vor dem vereinbarten Termin kostenlos stornieren. Kontaktieren Sie uns, um Ihre Bestellung zu stornieren.
          </p>
        </details>



     


      

      </section>
      {/*<!-- End Basic accordion --> */}
      </div>
        {/*add 3 card To follow us on social media */}
      <div>
      <div className='
      flex flex-col items-center justify-center w-full px-4 py-8 mx-auto md:px-10 md:py-16 lg:px-12 lg:py-20 xl:px-16 xl:py-24 2xl:px-20 2xl:py-28'>
      <h1 className='text-4xl font-bold text-center mt-3'>Folgen Sie uns auf Social Media</h1>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-8'>

      <div className='flex flex-col items-center justify-center cursor-pointer'>
        <a href="https://www.facebook.com/umzugstruppewien">
      <img className='w-32 h-32' src="../../../img/social/facebook.png" alt="" />
      <p className='text-xl font-bold text-center mt-3 uppercase'>Facebook</p>
      </a>
      </div>

      <div className='flex flex-col items-center justify-center cursor-pointer'>
      <a href="https://www.instagram.com/umzugstruppe/">
      <img className='w-32 h-32' src="../../../img/social/instagram.png" alt="" />
      <p className='text-xl font-bold text-center mt-3 uppercase'>Instagram</p>
      </a>
      </div>

  
      </div>
      </div>
      </div>
      </div>
    );
  }
}
