import React from 'react';
import './App.css';

import WebApp from 'Elements/WebApp';

function App() {
  return (
    <div>
      <WebApp />

    </div>
  );
}

export default App;
