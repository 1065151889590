import * as React from 'react';

export interface IBannerProps {
    pageName: string;
    color: string;
    isHome:boolean

}

export interface IBannerState {
}

export default class Banner extends React.Component<IBannerProps, IBannerState> {
  constructor(props: IBannerProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
          <div className={`relative z-10 overflow-hidden pt-[80px] pb-[60px] md:pt-[130px] lg:pt-[90px] bg-${this.props.color}`}>
    <div className={`w-full h-px bg-gradient-to-r from-stroke/0 via-stroke to-stroke/0 absolute left-0 bottom-0`}>
    </div>
    {!this.props.isHome &&
    <div className="container">
      <div className="-mx-4 flex flex-wrap items-center">
        <div className="w-full px-4">
          <div className="text-center">
            <h1 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] md:leading-[1.2]">{this.props.pageName}</h1>
           

            <ul className="flex items-center justify-center gap-[10px]">
              <li className="flex items-center gap-[10px] text-base font-medium text-dark">
                  Home
              </li>
              <li  className="flex items-center gap-[10px] text-base font-medium text-body-color">
                  <span className={`text-${this.props.color}`}> / </span>
                 {this.props.pageName}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  }
  </div>
      </div>
    );
  }
}
