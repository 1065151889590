import * as React from 'react';
import Banner from '../Section/Banner';
import ServicesSection from 'Elements/Section/ServicesSection';

export interface IOldFurnitureProps {
}

export interface IOldFurnitureState {
}

export default class OldFurniture extends React.Component<IOldFurnitureProps, IOldFurnitureState> {
  constructor(props: IOldFurnitureProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className=' p-6'>
    <div>
        <img
            src="../../../img/service/h-7.jpg"
            alt="Möbel bewegen"
            className="w-full h-fit object-cover mt-24 mx-auto"
            />
    </div>
    <Banner isHome={false} pageName="Alte Möbel loswerden" color="white"/>
  
    <div className='container shadow-lg p-3'>
        <p className="text-left text-gray-700 my-4 mx-3">
        Von Zeit zu Zeit eine Bestandsaufnahme des Hauses durchzuführen, kann Sie überraschen, da Sie feststellen, dass Sie einige Dinge haben, die Sie nicht benötigen, und wenn Sie diesen Vorgang nicht von Zeit zu Zeit durchführen, wenn Sie an einen neuen Ort ziehen Sie werden überrascht sein. Beim Umzug müssen Sie die alten Möbel gegen neue austauschen, die zum neuen Haus passen, oder Sie müssen einige davon entfernen, da dies möglicherweise überflüssig ist, aber hier stellt sich die Frage, was Um mit all den Dingen und alten Möbeln zu tun, die wir nicht brauchen, müssen wir hier Dinge klassifizieren, um zu bestimmen, wie sie entsorgt oder mit größtmöglichem Nutzen beseitigt werden sollen
        </p>
    </div>
    <div className='container shadow-lg p-3'>
        <h2 className="lg:text-2xl font-bold text-gray-800 text-center my-4">Was ist die Natur der unerwünschten Dinge oder zu entsorgen?</h2>
        <ul className='list-none list-inside text-left text-gray-700 m-4'>
            <li className="text-gray-700 m-4">Möbel, die nicht mehr benötigt werden</li>
            <li className="text-gray-700 m-4">Kleidung, Stoffe und Schuhe.</li>
            <li className="text-gray-700 m-4">Elektronik und Elektrogeräte.</li>
            <li className="text-gray-700 m-4">Alte Bücher und Zeitungen.</li>
            <li className="text-gray-700 m-4">Alte Spielzeuge und Spiele.</li>
        </ul>
    </div>            
    {/* card zufriedene Kunden Pakete moved Jahre Dienst with number and icon*/}
         {/*<!-- Component: Three columns even layout --> */}
        <section className='container shadow-lg p-3'>

        <div className="px-6 m-auto my-6">
        <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
        <div className="col-span-4">
                                {/*<!-- Component: Card with icon --> */}
                <div className="overflow-hidden text-center bg-white rounded shadow-md text-slate-500 shadow-slate-200">
                    {/*  <!-- Icon --> */}
                    <svg
                        className="w-12 h-12 p-3 mx-auto text-white bg-red-500 rounded-full"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        >
                    </svg>
                    {/*  <!-- Body--> */}
                    <div className="p-6">
                    <h3 className="mb-4 text-xl font-medium text-slate-700">143 +</h3>
                    <p>
                    zufriedene Kunden
                    </p>
                    </div>
                </div>
        </div>
            <div className="col-span-4"> 
                                            {/*<!-- Component: Card with icon --> */}
                                            <div className="overflow-hidden text-center bg-white rounded shadow-md text-slate-500 shadow-slate-200">
                    {/*  <!-- Icon --> */}
                    <svg
                    
                        className="w-12 h-12 p-3 mx-auto text-white bg-green-500 rounded-full"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        >
                    </svg>
                    {/*  <!-- Body--> */}
                    <div className="p-6">
                    <h3 className="mb-4 text-xl font-medium text-slate-700">7 +</h3>
                    <p>
                    Jahre Dienst
                    </p>
                    </div>
                </div>
            
            </div>
            <div className="col-span-4">
                    {/*<!-- Component: Card with icon --> */}
                    <div className="overflow-hidden text-center bg-white rounded shadow-md text-slate-500 shadow-slate-200">
                    {/*  <!-- Icon --> */}
                    <svg
                        className="w-12 h-12 p-3 mx-auto text-white bg-blue-500 rounded-full"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        >
                    </svg>
                    {/*  <!-- Body--> */}
                    <div className="p-6">
                    <h3 className="mb-4 text-xl font-medium text-slate-700">500 +</h3>
                    <p>
                    Pakete moved
                    </p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- End Three columns even layout --> */}
      <ServicesSection
        services={[
          {
            title: "Möbel bewegen",
            description:
              "Unser Umzugsservice sorgt für einen stressfreien Übergang in Ihr neues Zuhause. Wir kümmern uns um alle Aspekte des Umzugs, vom sorgfältigen Verpacken Ihrer Sachen über den sicheren Transport bis hin zum sorgfältigen Auspacken in Ihren neuen Räumlichkeiten. Unser Team behandelt jeden Artikel mit größter Sorgfalt und stellt sicher, dass alles in einwandfreiem Zustand ankommt.",
            imageSrc:"../img/projects/p3.webp",
            company: "Anzahl der Paneele: 20",
          },
          {
            title: "Büroumzug",
            description:
              "Der Umzug eines Büros erfordert Präzision und Effizienz, und unser Büroumzugsservice ist darauf ausgelegt, Ausfallzeiten zu minimieren. Wir kümmern uns um den gesamten Prozess, vom Abbau der Arbeitsplätze über den sicheren Transport der Technik bis hin zum Aufbau in Ihren neuen Büroräumen, damit Sie den Betrieb reibungslos und schnell wieder aufnehmen können.",
            imageSrc: "../img/projects/p2.webp",
            company: "Anzahl der Paneele: 500",
          },
          {
            title: "Ein- und Auspackdienste",
            description:
              "Unsere umfassenden Ein- und Auspackdienste machen den Umzug zum Kinderspiel. Wir stellen alle Materialien und Fachkenntnisse zur Verfügung, die zum Schutz Ihres Eigentums erforderlich sind, von empfindlichen Glaswaren bis hin zu Büchern und Kleidung. Bei Ihrer Ankunft packen wir auch Ihre Sachen aus und ordnen sie gemäß Ihren Anweisungen, sodass Sie sich in Ihrem neuen Zuhause sofort wie zu Hause fühlen.",
            imageSrc:"../img/projects/P1.webp",
            company: "Anzahl der Paneele: 100",
          },
        ]}
      />
      <section className='container shadow-lg p-3'>
        <div className="container px-6 m-auto">
            <h2 className="text-3xl font-bold text-gray-800 mb-4 mt-4">
            Was ist der erwartete Preis?
            </h2>
            <p className="text-gray-700 m-4">
            Diese Frage kann nicht direkt beantwortet werden, da für jedes Haus ein anderer Preis festgelegt wird, der von den zu entsorgenden Waren oder Möbeln abhängt.
            Daher ist die Bewertung für die Kostenschätzung von entscheidender Bedeutung, ist für Sie völlig unverbindlich, kostenlos und erfordert normalerweise keine lange Wartezeit. Wir können Ihnen dann ein Angebot machen, indem wir Sie per E-Mail oder direkt kontaktieren</p>

        </div>
      </section>




</div>
    );
  }
}
