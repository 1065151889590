import * as React from 'react';
import ServicesList from '../Section/ServicesList';
import Banner from '../Section/Banner';
import CardImage from '../Section/CardImage';
import RequestForm from '../Section/RequestForm';
// add all apple devices and their respective models here
interface ServiceData {
    [device: string]: {
      [version: string]: {
        [service: string]: number;
      };
    };
  }
interface newServiceData {
    [device: string]: {
      [version: string]: {
        [versionNumber:string]:{
        [service: string]: number;
      };
    }
    };
  }
  
  const servicesData: newServiceData = {

    "Mobile Phone": {
   
    "iPhone 6": {
        "iPhone 6s":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone 6s Plus":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        }
        },
    "iPhone 7": {
        "iPhone 7":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone 7 Plus":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        }
        },
    "iPhone 8": {
        "iPhone 8":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone 8 Plus":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        }
        },
 
    "iPhone 9": {
        "iPhone SE":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone SE (2. Gen.)":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone SE (3. Gen.)":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        }
        },
    "iPhone 10": {
        "iPhone XS Max":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone XS":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone X":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        "iPhone XR":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        },
    "iPhone 11": {
        "iPhone Pro Max":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone Pro":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone 11":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        },
    "iPhone 12": {
        "iPhone Pro Max":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone Pro":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone 12":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        "iPhone 12 mini":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        },
    "iPhone 13": {
        "iPhone Pro Max":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone Pro":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone 13":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        "iPhone 13 mini":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        },
    "iPhone 14": {
        "iPhone Pro Max":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone Pro":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone 14":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        "iPhone 14 Plus":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        },
    "iPhone 15": {
        "iPhone Pro Max":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone Pro":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPhone 15":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        "iPhone 15 Plus":{
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },
        },

    },
    "Mac & More":{
        "MacBook Air":{
            "MacBook Air (11-inch, Early 2015)":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,

            },
            "MacBook Air (13-inch, Early 2015)":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,

            },
            "MacBook Air (13-inch, 2017)":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,

            },
            "MacBook Air (Retina, 13-inch, 2018)":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,

            },
            "MacBook Air (Retina, 13-inch, 2019)":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,

            },
            "MacBook Air (Retina, 13-inch, 2020)":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,

            },
            "MacBook Air (M2, 2022)":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,

            },
            },
        "MacBook Pro":{
            "MacBook Pro 13":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,

            },
            "MacBook Pro 14":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,


            },
            "MacBook Pro 15":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,


            },
            "MacBook Pro 16":{
                "Display Tausch": 50,
                "Topcase Tausch": 80,
                "Akku Tausch": 30,
                "Mainboard Tausch": 30,
                "I/O Board Tausch ": 30,
                "Trackpad Tausch ": 30,
                "Bottomcase Tausch ": 30,
                "Wasser Schaden": 30,
                "Sonstige": 30,


            },
        },

        "iMac":{
            "iMac 21":{
                "Upgrade ": 30,
                "Akku/ Netztel": 30,
                "Topcase Tausch": 80,
                "Sonstige": 30,
              

            },
            "iMac 24":{
                "Upgrade ": 30,
                "Akku/ Netztel": 30,
                "Topcase Tausch": 80,
                "Sonstige": 30,
              

            },
            "iMac 27":{
                "Upgrade ": 30,
                "Akku/ Netztel": 30,
                "Topcase Tausch": 80,
                "Sonstige": 30,
              

            },
        },
        "Mac Pro":{
            "Mac Pro 2013":{
                "Graphig Karte Upgrade ": 30,
                "Memmory Upgrade ": 30,
                "Daten Verwaltung ": 30,
                "Software Solution": 30,
                "Sonstige": 30,


            },
            "Mac Pro 2019":{

                "Graphig Karte Upgrade ": 30,
                "Memmory Upgrade ": 30,
                "Daten Verwaltung ": 30,
                "Software Solution": 30,
                "Sonstige": 30,

            },

        },
        "Mac mini":{
            "Mac mini 2014":{
                "Mainboard Tausch": 30,
                "Netztel": 30,
                "Daten Upgrade ": 30,
                "Sonstige": 30,

            },
            "Mac mini 2018":{
                "Mainboard Tausch": 30,
                "Netztel": 30,
                "Daten Upgrade ": 30,
                "Sonstige": 30,

            },

        },
        "Mac Studio":{
            "Mac Studio M1 MAX":{
                "Mainboard Tausch": 30,
                "Netztel": 30,
                "Daten Upgrade ": 30,
                "Sonstige": 30,

            },
            "Mac Studio M1 Ultra":{
                "Mainboard Tausch": 30,
                "Netztel": 30,
                "Daten Upgrade ": 30,
                "Sonstige": 30,

            },
            "Mac Studio M2 Max":{
                "Mainboard Tausch": 30,
                "Netztel": 30,
                "Daten Upgrade ": 30,
                "Sonstige": 30,

            },
            "Mac Studio M2 Ultra":{
                "Mainboard Tausch": 30,
                "Netztel": 30,
                "Daten Upgrade ": 30,
                "Sonstige": 30,

            },
        },
        "Mac Display":{
            "Mac Display 2019":{
                "Mainboard Tausch": 30,
                "Netztel": 30,
                "Daten Upgrade ": 30,
                "Sonstige": 30,

            },
        },
        "Mac Zubehör":{
            "Mac Zubehör 2021":{
                "Treiber- und Software-Kompatibilität": 30,
                "Software-Updates:": 30,
                "Energieverwaltung": 30,
                "Sonstige": 30,

            },
        },


        },
        "IPAD":{
        "iPad mini":{
                "iPad mini 4 Wi-Fi":{
                    "Bildschirm kaputt": 50,
                    "Wasserschaden": 80,
                    "Batteriewechsel": 30,
                    "Ladeanschluss": 30,
                    "Kameraaustausch": 30,
                    "Kopfhöreranschluss": 30,
                    "Home-Taste": 30,
                    "Power-Taste": 30,
                    "Lautstärketaste": 30,
                    "Vibratoraustausch": 30,
                    "Ohrlautsprecher-Austausch": 30,
                    "Lautsprecheraustausch": 30,
                    "Mikrofonaustausch": 30,
                    "Austausch der hinteren Abdeckung": 30,
                    "Ersatz des SIM-Kartenlesers": 30,
                    "Softwareprobleme": 30,
                    "Entsperren": 30,
                    "Flüssigkeitsschaden": 30,
                    "Datenwiederherstellung": 30,
                    "Motherboard-Reparatur": 30,
                    "Kein Einschalten": 30,
                    "Kein Signal": 30,
                    "Keine Hintergrundbeleuchtung": 30,
                    "Keine Anzeige": 30,
                    "Keine Berührung": 30,
                    "Kein WLAN": 30,
                    "Kein Ton": 30,
                    "Kein Service": 30,
                    "Kein Strom": 30,
                    "Kein Laden": 30,
                    "Kein Bild": 30,
                    "Keine Rückkamera": 30,
                    "Keine Frontkamera": 30,
                    "Kein Mikrofon": 30,
                    "Kein Ohrlautsprecher": 30,
                    "Kein Lautsprecher": 30,
                    "Keine Vibration": 30,
                },
                "iPad mini 4 Wi-Fi + Cellular":{
                    "Bildschirm kaputt": 50,
                    "Wasserschaden": 80,
                    "Batteriewechsel": 30,
                    "Ladeanschluss": 30,
                    "Kameraaustausch": 30,
                    "Kopfhöreranschluss": 30,
                    "Home-Taste": 30,
                    "Power-Taste": 30,
                    "Lautstärketaste": 30,
                    "Vibratoraustausch": 30,
                    "Ohrlautsprecher-Austausch": 30,
                    "Lautsprecheraustausch": 30,
                    "Mikrofonaustausch": 30,
                    "Austausch der hinteren Abdeckung": 30,
                    "Ersatz des SIM-Kartenlesers": 30,
                    "Softwareprobleme": 30,
                    "Entsperren": 30,
                    "Flüssigkeitsschaden": 30,
                    "Datenwiederherstellung": 30,
                    "Motherboard-Reparatur": 30,
                    "Kein Einschalten": 30,
                    "Kein Signal": 30,
                    "Keine Hintergrundbeleuchtung": 30,
                    "Keine Anzeige": 30,
                    "Keine Berührung": 30,
                    "Kein WLAN": 30,
                    "Kein Ton": 30,
                    "Kein Service": 30,
                    "Kein Strom": 30,
                    "Kein Laden": 30,
                    "Kein Bild": 30,
                    "Keine Rückkamera": 30,
                    "Keine Frontkamera": 30,
                    "Kein Mikrofon": 30,
                    "Kein Ohrlautsprecher": 30,
                    "Kein Lautsprecher": 30,
                    "Keine Vibration": 30,
                },
                "iPad mini 5 Wi-Fi":{
                    "Bildschirm kaputt": 50,
                    "Wasserschaden": 80,
                    "Batteriewechsel": 30,
                    "Ladeanschluss": 30,
                    "Kameraaustausch": 30,
                    "Kopfhöreranschluss": 30,
                    "Home-Taste": 30,
                    "Power-Taste": 30,
                    "Lautstärketaste": 30,
                    "Vibratoraustausch": 30,
                    "Ohrlautsprecher-Austausch": 30,
                    "Lautsprecheraustausch": 30,
                    "Mikrofonaustausch": 30,
                    "Austausch der hinteren Abdeckung": 30,
                    "Ersatz des SIM-Kartenlesers": 30,
                    "Softwareprobleme": 30,
                    "Entsperren": 30,
                    "Flüssigkeitsschaden": 30,
                    "Datenwiederherstellung": 30,
                    "Motherboard-Reparatur": 30,
                    "Kein Einschalten": 30,
                    "Kein Signal": 30,
                    "Keine Hintergrundbeleuchtung": 30,
                    "Keine Anzeige": 30,
                    "Keine Berührung": 30,
                    "Kein WLAN": 30,
                    "Kein Ton": 30,
                    "Kein Service": 30,
                    "Kein Strom": 30,
                    "Kein Laden": 30,
                    "Kein Bild": 30,
                    "Keine Rückkamera": 30,
                    "Keine Frontkamera": 30,
                    "Kein Mikrofon": 30,
                    "Kein Ohrlautsprecher": 30,
                    "Kein Lautsprecher": 30,
                    "Keine Vibration": 30,
                },
                "iPad mini 5 Wi-Fi + Cellular":{
                    "Bildschirm kaputt": 50,
                    "Wasserschaden": 80,
                    "Batteriewechsel": 30,
                    "Ladeanschluss": 30,
                    "Kameraaustausch": 30,
                    "Kopfhöreranschluss": 30,
                    "Home-Taste": 30,
                    "Power-Taste": 30,
                    "Lautstärketaste": 30,
                    "Vibratoraustausch": 30,
                    "Ohrlautsprecher-Austausch": 30,
                    "Lautsprecheraustausch": 30,
                    "Mikrofonaustausch": 30,
                    "Austausch der hinteren Abdeckung": 30,
                    "Ersatz des SIM-Kartenlesers": 30,
                    "Softwareprobleme": 30,
                    "Entsperren": 30,
                    "Flüssigkeitsschaden": 30,
                    "Datenwiederherstellung": 30,
                    "Motherboard-Reparatur": 30,
                    "Kein Einschalten": 30,
                    "Kein Signal": 30,
                    "Keine Hintergrundbeleuchtung": 30,
                    "Keine Anzeige": 30,
                    "Keine Berührung": 30,
                    "Kein WLAN": 30,
                    "Kein Ton": 30,
                    "Kein Service": 30,
                    "Kein Strom": 30,
                    "Kein Laden": 30,
                    "Kein Bild": 30,
                    "Keine Rückkamera": 30,
                    "Keine Frontkamera": 30,
                    "Kein Mikrofon": 30,
                    "Kein Ohrlautsprecher": 30,
                    "Kein Lautsprecher": 30,
                    "Keine Vibration": 30,
                },
                "iPad mini 6 Wi-Fi":{
                    "Bildschirm kaputt": 50,
                    "Wasserschaden": 80,
                    "Batteriewechsel": 30,
                    "Ladeanschluss": 30,
                    "Kameraaustausch": 30,
                    "Kopfhöreranschluss": 30,
                    "Home-Taste": 30,
                    "Power-Taste": 30,
                    "Lautstärketaste": 30,
                    "Vibratoraustausch": 30,
                    "Ohrlautsprecher-Austausch": 30,
                    "Lautsprecheraustausch": 30,
                    "Mikrofonaustausch": 30,
                    "Austausch der hinteren Abdeckung": 30,
                    "Ersatz des SIM-Kartenlesers": 30,
                    "Softwareprobleme": 30,
                    "Entsperren": 30,
                    "Flüssigkeitsschaden": 30,
                    "Datenwiederherstellung": 30,
                    "Motherboard-Reparatur": 30,
                    "Kein Einschalten": 30,
                    "Kein Signal": 30,
                    "Keine Hintergrundbeleuchtung": 30,
                    "Keine Anzeige": 30,
                    "Keine Berührung": 30,
                    "Kein WLAN": 30,
                    "Kein Ton": 30,
                    "Kein Service": 30,
                    "Kein Strom": 30,
                    "Kein Laden": 30,
                    "Kein Bild": 30,
                    "Keine Rückkamera": 30,
                    "Keine Frontkamera": 30,
                    "Kein Mikrofon": 30,
                    "Kein Ohrlautsprecher": 30,
                    "Kein Lautsprecher": 30,
                    "Keine Vibration": 30,
                },
                "iPad mini 6 Wi-Fi + Cellular":{
                    "Bildschirm kaputt": 50,
                    "Wasserschaden": 80,
                    "Batteriewechsel": 30,
                    "Ladeanschluss": 30,
                    "Kameraaustausch": 30,
                    "Kopfhöreranschluss": 30,
                    "Home-Taste": 30,
                    "Power-Taste": 30,
                    "Lautstärketaste": 30,
                    "Vibratoraustausch": 30,
                    "Ohrlautsprecher-Austausch": 30,
                    "Lautsprecheraustausch": 30,
                    "Mikrofonaustausch": 30,
                    "Austausch der hinteren Abdeckung": 30,
                    "Ersatz des SIM-Kartenlesers": 30,
                    "Softwareprobleme": 30,
                    "Entsperren": 30,
                    "Flüssigkeitsschaden": 30,
                    "Datenwiederherstellung": 30,
                    "Motherboard-Reparatur": 30,
                    "Kein Einschalten": 30,
                    "Kein Signal": 30,
                    "Keine Hintergrundbeleuchtung": 30,
                    "Keine Anzeige": 30,
                    "Keine Berührung": 30,
                    "Kein WLAN": 30,
                    "Kein Ton": 30,
                    "Kein Service": 30,
                    "Kein Strom": 30,
                    "Kein Laden": 30,
                    "Kein Bild": 30,
                    "Keine Rückkamera": 30,
                    "Keine Frontkamera": 30,
                    "Kein Mikrofon": 30,
                    "Kein Ohrlautsprecher": 30,
                    "Kein Lautsprecher": 30,
                    "Keine Vibration": 30,
                },           
            },
        "iPad":{
                    "iPad 6 Wi-Fi":{
                        "Bildschirm kaputt": 50,
                        "Wasserschaden": 80,
                        "Batteriewechsel": 30,
                        "Ladeanschluss": 30,
                        "Kameraaustausch": 30,
                        "Kopfhöreranschluss": 30,
                        "Home-Taste": 30,
                        "Power-Taste": 30,
                        "Lautstärketaste": 30,
                        "Vibratoraustausch": 30,
                        "Ohrlautsprecher-Austausch": 30,
                        "Lautsprecheraustausch": 30,
                        "Mikrofonaustausch": 30,
                        "Austausch der hinteren Abdeckung": 30,
                        "Ersatz des SIM-Kartenlesers": 30,
                        "Softwareprobleme": 30,
                        "Entsperren": 30,
                        "Flüssigkeitsschaden": 30,
                        "Datenwiederherstellung": 30,
                        "Motherboard-Reparatur": 30,
                        "Kein Einschalten": 30,
                        "Kein Signal": 30,
                        "Keine Hintergrundbeleuchtung": 30,
                        "Keine Anzeige": 30,
                        "Keine Berührung": 30,
                        "Kein WLAN": 30,
                        "Kein Ton": 30,
                        "Kein Service": 30,
                        "Kein Strom": 30,
                        "Kein Laden": 30,
                        "Kein Bild": 30,
                        "Keine Rückkamera": 30,
                        "Keine Frontkamera": 30,
                        "Kein Mikrofon": 30,
                        "Kein Ohrlautsprecher": 30,
                        "Kein Lautsprecher": 30,
                        "Keine Vibration": 30,
                    },
                    "iPad 6 Wi-Fi + Cellular":{
                        "Bildschirm kaputt": 50,
                        "Wasserschaden": 80,
                        "Batteriewechsel": 30,
                        "Ladeanschluss": 30,
                        "Kameraaustausch": 30,
                        "Kopfhöreranschluss": 30,
                        "Home-Taste": 30,
                        "Power-Taste": 30,
                        "Lautstärketaste": 30,
                        "Vibratoraustausch": 30,
                        "Ohrlautsprecher-Austausch": 30,
                        "Lautsprecheraustausch": 30,
                        "Mikrofonaustausch": 30,
                        "Austausch der hinteren Abdeckung": 30,
                        "Ersatz des SIM-Kartenlesers": 30,
                        "Softwareprobleme": 30,
                        "Entsperren": 30,
                        "Flüssigkeitsschaden": 30,
                        "Datenwiederherstellung": 30,
                        "Motherboard-Reparatur": 30,
                        "Kein Einschalten": 30,
                        "Kein Signal": 30,
                        "Keine Hintergrundbeleuchtung": 30,
                        "Keine Anzeige": 30,
                        "Keine Berührung": 30,
                        "Kein WLAN": 30,
                        "Kein Ton": 30,
                        "Kein Service": 30,
                        "Kein Strom": 30,
                        "Kein Laden": 30,
                        "Kein Bild": 30,
                        "Keine Rückkamera": 30,
                        "Keine Frontkamera": 30,
                        "Kein Mikrofon": 30,
                        "Kein Ohrlautsprecher": 30,
                        "Kein Lautsprecher": 30,
                        "Keine Vibration": 30,
                    },
                    "iPad 7 Wi-Fi":{
                        "Bildschirm kaputt": 50,
                        "Wasserschaden": 80,
                        "Batteriewechsel": 30,
                        "Ladeanschluss": 30,
                        "Kameraaustausch": 30,
                        "Kopfhöreranschluss": 30,
                        "Home-Taste": 30,
                        "Power-Taste": 30,
                        "Lautstärketaste": 30,
                        "Vibratoraustausch": 30,
                        "Ohrlautsprecher-Austausch": 30,
                        "Lautsprecheraustausch": 30,
                        "Mikrofonaustausch": 30,
                        "Austausch der hinteren Abdeckung": 30,
                        "Ersatz des SIM-Kartenlesers": 30,
                        "Softwareprobleme": 30,
                        "Entsperren": 30,
                        "Flüssigkeitsschaden": 30,
                        "Datenwiederherstellung": 30,
                        "Motherboard-Reparatur": 30,
                        "Kein Einschalten": 30,
                        "Kein Signal": 30,
                        "Keine Hintergrundbeleuchtung": 30,
                        "Keine Anzeige": 30,
                        "Keine Berührung": 30,
                        "Kein WLAN": 30,
                        "Kein Ton": 30,
                        "Kein Service": 30,
                        "Kein Strom": 30,
                        "Kein Laden": 30,
                        "Kein Bild": 30,
                        "Keine Rückkamera": 30,
                        "Keine Frontkamera": 30,
                        "Kein Mikrofon": 30,
                        "Kein Ohrlautsprecher": 30,
                        "Kein Lautsprecher": 30,
                        "Keine Vibration": 30,
                    },
                    "iPad 7 Wi-Fi + Cellular":{
                        "Bildschirm kaputt": 50,
                        "Wasserschaden": 80,
                        "Batteriewechsel": 30,
                        "Ladeanschluss": 30,
                        "Kameraaustausch": 30,
                        "Kopfhöreranschluss": 30,
                        "Home-Taste": 30,
                        "Power-Taste": 30,
                        "Lautstärketaste": 30,
                        "Vibratoraustausch": 30,
                        "Ohrlautsprecher-Austausch": 30,
                        "Lautsprecheraustausch": 30,
                        "Mikrofonaustausch": 30,
                        "Austausch der hinteren Abdeckung": 30,
                        "Ersatz des SIM-Kartenlesers": 30,
                        "Softwareprobleme": 30,
                        "Entsperren": 30,
                        "Flüssigkeitsschaden": 30,
                        "Datenwiederherstellung": 30,
                        "Motherboard-Reparatur": 30,
                        "Kein Einschalten": 30,
                        "Kein Signal": 30,
                        "Keine Hintergrundbeleuchtung": 30,
                        "Keine Anzeige": 30,
                        "Keine Berührung": 30,
                        "Kein WLAN": 30,
                        "Kein Ton": 30,
                        "Kein Service": 30,
                        "Kein Strom": 30,
                        "Kein Laden": 30,
                        "Kein Bild": 30,
                        "Keine Rückkamera": 30,
                        "Keine Frontkamera": 30,
                        "Kein Mikrofon": 30,
                        "Kein Ohrlautsprecher": 30,
                        "Kein Lautsprecher": 30,
                        "Keine Vibration": 30,
                    },
                    "iPad 8 Wi-Fi":{
                        "Bildschirm kaputt": 50,
                        "Wasserschaden": 80,
                        "Batteriewechsel": 30,
                        "Ladeanschluss": 30,
                        "Kameraaustausch": 30,
                        "Kopfhöreranschluss": 30,
                        "Home-Taste": 30,
                        "Power-Taste": 30,
                        "Lautstärketaste": 30,
                        "Vibratoraustausch": 30,
                        "Ohrlautsprecher-Austausch": 30,
                        "Lautsprecheraustausch": 30,
                        "Mikrofonaustausch": 30,
                        "Austausch der hinteren Abdeckung": 30,
                        "Ersatz des SIM-Kartenlesers": 30,
                        "Softwareprobleme": 30,
                        "Entsperren": 30,
                        "Flüssigkeitsschaden": 30,
                        "Datenwiederherstellung": 30,
                        "Motherboard-Reparatur": 30,
                        "Kein Einschalten": 30,
                        "Kein Signal": 30,
                        "Keine Hintergrundbeleuchtung": 30,
                        "Keine Anzeige": 30,
                        "Keine Berührung": 30,
                        "Kein WLAN": 30,
                        "Kein Ton": 30,
                        "Kein Service": 30,
                        "Kein Strom": 30,
                        "Kein Laden": 30,
                        "Kein Bild": 30,
                        "Keine Rückkamera": 30,
                        "Keine Frontkamera": 30,
                        "Kein Mikrofon": 30,
                        "Kein Ohrlautsprecher": 30,
                        "Kein Lautsprecher": 30,
                        "Keine Vibration": 30,
                    },
                    "iPad 8 Wi-Fi + Cellular":{
                        "Bildschirm kaputt": 50,
                        "Wasserschaden": 80,
                        "Batteriewechsel": 30,
                        "Ladeanschluss": 30,
                        "Kameraaustausch": 30,
                        "Kopfhöreranschluss": 30,
                        "Home-Taste": 30,
                        "Power-Taste": 30,
                        "Lautstärketaste": 30,
                        "Vibratoraustausch": 30,
                        "Ohrlautsprecher-Austausch": 30,
                        "Lautsprecheraustausch": 30,
                        "Mikrofonaustausch": 30,
                        "Austausch der hinteren Abdeckung": 30,
                        "Ersatz des SIM-Kartenlesers": 30,
                        "Softwareprobleme": 30,
                        "Entsperren": 30,
                        "Flüssigkeitsschaden": 30,
                        "Datenwiederherstellung": 30,
                        "Motherboard-Reparatur": 30,
                        "Kein Einschalten": 30,
                        "Kein Signal": 30,
                        "Keine Hintergrundbeleuchtung": 30,
                        "Keine Anzeige": 30,
                        "Keine Berührung": 30,
                        "Kein WLAN": 30,
                        "Kein Ton": 30,
                        "Kein Service": 30,
                        "Kein Strom": 30,
                        "Kein Laden": 30,
                        "Kein Bild": 30,
                        "Keine Rückkamera": 30,
                        "Keine Frontkamera": 30,
                        "Kein Mikrofon": 30,
                        "Kein Ohrlautsprecher": 30,
                        "Kein Lautsprecher": 30,
                        "Keine Vibration": 30,
                    },
                    "iPad 9 Wi-Fi":{
                        "Bildschirm kaputt": 50,
                        "Wasserschaden": 80,
                        "Batteriewechsel": 30,
                        "Ladeanschluss": 30,
                        "Kameraaustausch": 30,
                        "Kopfhöreranschluss": 30,
                        "Home-Taste": 30,
                        "Power-Taste": 30,
                        "Lautstärketaste": 30,
                        "Vibratoraustausch": 30,
                        "Ohrlautsprecher-Austausch": 30,
                        "Lautsprecheraustausch": 30,
                        "Mikrofonaustausch": 30,
                        "Austausch der hinteren Abdeckung": 30,
                        "Ersatz des SIM-Kartenlesers": 30,
                        "Softwareprobleme": 30,
                        "Entsperren": 30,
                        "Flüssigkeitsschaden": 30,
                        "Datenwiederherstellung": 30,
                        "Motherboard-Reparatur": 30,
                        "Kein Einschalten": 30,
                        "Kein Signal": 30,
                        "Keine Hintergrundbeleuchtung": 30,
                        "Keine Anzeige": 30,
                        "Keine Berührung": 30,
                        "Kein WLAN": 30,
                        "Kein Ton": 30,
                        "Kein Service": 30,
                        "Kein Strom": 30,
                        "Kein Laden": 30,
                        "Kein Bild": 30,
                        "Keine Rückkamera": 30,
                        "Keine Frontkamera": 30,
                        "Kein Mikrofon": 30,
                        "Kein Ohrlautsprecher": 30,
                        "Kein Lautsprecher": 30,
                        "Keine Vibration": 30,
                    },
                    "iPad 9 Wi-Fi + Cellular":{
                        "Bildschirm kaputt": 50,
                        "Wasserschaden": 80,
                        "Batteriewechsel": 30,
                        "Ladeanschluss": 30,
                        "Kameraaustausch": 30,
                        "Kopfhöreranschluss": 30,
                        "Home-Taste": 30,
                        "Power-Taste": 30,
                        "Lautstärketaste": 30,
                        "Vibratoraustausch": 30,
                        "Ohrlautsprecher-Austausch": 30,
                        "Lautsprecheraustausch": 30,
                        "Mikrofonaustausch": 30,
                        "Austausch der hinteren Abdeckung": 30,
                        "Ersatz des SIM-Kartenlesers": 30,
                        "Softwareprobleme": 30,
                        "Entsperren": 30,
                        "Flüssigkeitsschaden": 30,
                        "Datenwiederherstellung": 30,
                        "Motherboard-Reparatur": 30,
                        "Kein Einschalten": 30,
                        "Kein Signal": 30,
                        "Keine Hintergrundbeleuchtung": 30,
                        "Keine Anzeige": 30,
                        "Keine Berührung": 30,
                        "Kein WLAN": 30,
                        "Kein Ton": 30,
                        "Kein Service": 30,
                        "Kein Strom": 30,
                        "Kein Laden": 30,
                        "Kein Bild": 30,
                        "Keine Rückkamera": 30,
                        "Keine Frontkamera": 30,
                        "Kein Mikrofon": 30,
                        "Kein Ohrlautsprecher": 30,
                        "Kein Lautsprecher": 30,
                        "Keine Vibration": 30,
                    },
                    "iPad 10 Wi-Fi":{
                        "Bildschirm kaputt": 50,
                        "Wasserschaden": 80,
                        "Batteriewechsel": 30,
                        "Ladeanschluss": 30,
                        "Kameraaustausch": 30,
                        "Kopfhöreranschluss": 30,
                        "Home-Taste": 30,
                        "Power-Taste": 30,
                        "Lautstärketaste": 30,
                        "Vibratoraustausch": 30,
                        "Ohrlautsprecher-Austausch": 30,
                        "Lautsprecheraustausch": 30,
                        "Mikrofonaustausch": 30,
                        "Austausch der hinteren Abdeckung": 30,
                        "Ersatz des SIM-Kartenlesers": 30,
                        "Softwareprobleme": 30,
                        "Entsperren": 30,
                        "Flüssigkeitsschaden": 30,
                        "Datenwiederherstellung": 30,
                        "Motherboard-Reparatur": 30,
                        "Kein Einschalten": 30,
                        "Kein Signal": 30,
                        "Keine Hintergrundbeleuchtung": 30,
                        "Keine Anzeige": 30,
                        "Keine Berührung": 30,
                        "Kein WLAN": 30,
                        "Kein Ton": 30,
                        "Kein Service": 30,
                        "Kein Strom": 30,
                        "Kein Laden": 30,
                        "Kein Bild": 30,
                        "Keine Rückkamera": 30,
                        "Keine Frontkamera": 30,
                        "Kein Mikrofon": 30,
                        "Kein Ohrlautsprecher": 30,
                        "Kein Lautsprecher": 30,
                        "Keine Vibration": 30,
                    },
                    "iPad 10 Wi-Fi + Cellular":{
                        "Bildschirm kaputt": 50,
                        "Wasserschaden": 80,
                        "Batteriewechsel": 30,
                        "Ladeanschluss": 30,
                        "Kameraaustausch": 30,
                        "Kopfhöreranschluss": 30,
                        "Home-Taste": 30,
                        "Power-Taste": 30,
                        "Lautstärketaste": 30,
                        "Vibratoraustausch": 30,
                        "Ohrlautsprecher-Austausch": 30,
                        "Lautsprecheraustausch": 30,
                        "Mikrofonaustausch": 30,
                        "Austausch der hinteren Abdeckung": 30,
                        "Ersatz des SIM-Kartenlesers": 30,
                        "Softwareprobleme": 30,
                        "Entsperren": 30,
                        "Flüssigkeitsschaden": 30,
                        "Datenwiederherstellung": 30,
                        "Motherboard-Reparatur": 30,
                        "Kein Einschalten": 30,
                        "Kein Signal": 30,
                        "Keine Hintergrundbeleuchtung": 30,
                        "Keine Anzeige": 30,
                        "Keine Berührung": 30,
                        "Kein WLAN": 30,
                        "Kein Ton": 30,
                        "Kein Service": 30,
                        "Kein Strom": 30,
                        "Kein Laden": 30,
                        "Kein Bild": 30,
                        "Keine Rückkamera": 30,
                        "Keine Frontkamera": 30,
                        "Kein Mikrofon": 30,
                        "Kein Ohrlautsprecher": 30,
                        "Kein Lautsprecher": 30,
                        "Keine Vibration": 30,
                    },
            },
        "iPad Air":{
                        "iPad Air 3 Wi-Fi":{
                            "Bildschirm kaputt": 50,
                            "Wasserschaden": 80,
                            "Batteriewechsel": 30,
                            "Ladeanschluss": 30,
                            "Kameraaustausch": 30,
                            "Kopfhöreranschluss": 30,
                            "Home-Taste": 30,
                            "Power-Taste": 30,
                            "Lautstärketaste": 30,
                            "Vibratoraustausch": 30,
                            "Ohrlautsprecher-Austausch": 30,
                            "Lautsprecheraustausch": 30,
                            "Mikrofonaustausch": 30,
                            "Austausch der hinteren Abdeckung": 30,
                            "Ersatz des SIM-Kartenlesers": 30,
                            "Softwareprobleme": 30,
                            "Entsperren": 30,
                            "Flüssigkeitsschaden": 30,
                            "Datenwiederherstellung": 30,
                            "Motherboard-Reparatur": 30,
                            "Kein Einschalten": 30,
                            "Kein Signal": 30,
                            "Keine Hintergrundbeleuchtung": 30,
                            "Keine Anzeige": 30,
                            "Keine Berührung": 30,
                            "Kein WLAN": 30,
                            "Kein Ton": 30,
                            "Kein Service": 30,
                            "Kein Strom": 30,
                            "Kein Laden": 30,
                            "Kein Bild": 30,
                            "Keine Rückkamera": 30,
                            "Keine Frontkamera": 30,
                            "Kein Mikrofon": 30,
                            "Kein Ohrlautsprecher": 30,
                            "Kein Lautsprecher": 30,
                            "Keine Vibration": 30,
                        },
                        "iPad Air 3 Wi-Fi + Cellular":{
                            "Bildschirm kaputt": 50,
                            "Wasserschaden": 80,
                            "Batteriewechsel": 30,
                            "Ladeanschluss": 30,
                            "Kameraaustausch": 30,
                            "Kopfhöreranschluss": 30,
                            "Home-Taste": 30,
                            "Power-Taste": 30,
                            "Lautstärketaste": 30,
                            "Vibratoraustausch": 30,
                            "Ohrlautsprecher-Austausch": 30,
                            "Lautsprecheraustausch": 30,
                            "Mikrofonaustausch": 30,
                            "Austausch der hinteren Abdeckung": 30,
                            "Ersatz des SIM-Kartenlesers": 30,
                            "Softwareprobleme": 30,
                            "Entsperren": 30,
                            "Flüssigkeitsschaden": 30,
                            "Datenwiederherstellung": 30,
                            "Motherboard-Reparatur": 30,
                            "Kein Einschalten": 30,
                            "Kein Signal": 30,
                            "Keine Hintergrundbeleuchtung": 30,
                            "Keine Anzeige": 30,
                            "Keine Berührung": 30,
                            "Kein WLAN": 30,
                            "Kein Ton": 30,
                            "Kein Service": 30,
                            "Kein Strom": 30,
                            "Kein Laden": 30,
                            "Kein Bild": 30,
                            "Keine Rückkamera": 30,
                            "Keine Frontkamera": 30,
                            "Kein Mikrofon": 30,
                            "Kein Ohrlautsprecher": 30,
                            "Kein Lautsprecher": 30,
                            "Keine Vibration": 30,
                        },
                        "iPad Air 4 Wi-Fi":{
                            "Bildschirm kaputt": 50,
                            "Wasserschaden": 80,
                            "Batteriewechsel": 30,
                            "Ladeanschluss": 30,
                            "Kameraaustausch": 30,
                            "Kopfhöreranschluss": 30,
                            "Home-Taste": 30,
                            "Power-Taste": 30,
                            "Lautstärketaste": 30,
                            "Vibratoraustausch": 30,
                            "Ohrlautsprecher-Austausch": 30,
                            "Lautsprecheraustausch": 30,
                            "Mikrofonaustausch": 30,
                            "Austausch der hinteren Abdeckung": 30,
                            "Ersatz des SIM-Kartenlesers": 30,
                            "Softwareprobleme": 30,
                            "Entsperren": 30,
                            "Flüssigkeitsschaden": 30,
                            "Datenwiederherstellung": 30,
                            "Motherboard-Reparatur": 30,
                            "Kein Einschalten": 30,
                            "Kein Signal": 30,
                            "Keine Hintergrundbeleuchtung": 30,
                            "Keine Anzeige": 30,
                            "Keine Berührung": 30,
                            "Kein WLAN": 30,
                            "Kein Ton": 30,
                            "Kein Service": 30,
                            "Kein Strom": 30,
                            "Kein Laden": 30,
                            "Kein Bild": 30,
                            "Keine Rückkamera": 30,
                            "Keine Frontkamera": 30,
                            "Kein Mikrofon": 30,
                            "Kein Ohrlautsprecher": 30,
                            "Kein Lautsprecher": 30,
                            "Keine Vibration": 30,
                        },
                        "iPad Air 4 Wi-Fi + Cellular":{
                            "Bildschirm kaputt": 50,
                            "Wasserschaden": 80,
                            "Batteriewechsel": 30,
                            "Ladeanschluss": 30,
                            "Kameraaustausch": 30,
                            "Kopfhöreranschluss": 30,
                            "Home-Taste": 30,
                            "Power-Taste": 30,
                            "Lautstärketaste": 30,
                            "Vibratoraustausch": 30,
                            "Ohrlautsprecher-Austausch": 30,
                            "Lautsprecheraustausch": 30,
                            "Mikrofonaustausch": 30,
                            "Austausch der hinteren Abdeckung": 30,
                            "Ersatz des SIM-Kartenlesers": 30,
                            "Softwareprobleme": 30,
                            "Entsperren": 30,
                            "Flüssigkeitsschaden": 30,
                            "Datenwiederherstellung": 30,
                            "Motherboard-Reparatur": 30,
                            "Kein Einschalten": 30,
                            "Kein Signal": 30,
                            "Keine Hintergrundbeleuchtung": 30,
                            "Keine Anzeige": 30,
                            "Keine Berührung": 30,
                            "Kein WLAN": 30,
                            "Kein Ton": 30,
                            "Kein Service": 30,
                            "Kein Strom": 30,
                            "Kein Laden": 30,
                            "Kein Bild": 30,
                            "Keine Rückkamera": 30,
                            "Keine Frontkamera": 30,
                            "Kein Mikrofon": 30,
                            "Kein Ohrlautsprecher": 30,
                            "Kein Lautsprecher": 30,
                            "Keine Vibration": 30,
                        },
                        "iPad Air 5 Wi-Fi":{
                            "Bildschirm kaputt": 50,
                            "Wasserschaden": 80,
                            "Batteriewechsel": 30,
                            "Ladeanschluss": 30,
                            "Kameraaustausch": 30,
                            "Kopfhöreranschluss": 30,
                            "Home-Taste": 30,
                            "Power-Taste": 30,
                            "Lautstärketaste": 30,
                            "Vibratoraustausch": 30,
                            "Ohrlautsprecher-Austausch": 30,
                            "Lautsprecheraustausch": 30,
                            "Mikrofonaustausch": 30,
                            "Austausch der hinteren Abdeckung": 30,
                            "Ersatz des SIM-Kartenlesers": 30,
                            "Softwareprobleme": 30,
                            "Entsperren": 30,
                            "Flüssigkeitsschaden": 30,
                            "Datenwiederherstellung": 30,
                            "Motherboard-Reparatur": 30,
                            "Kein Einschalten": 30,
                            "Kein Signal": 30,
                            "Keine Hintergrundbeleuchtung": 30,
                            "Keine Anzeige": 30,
                            "Keine Berührung": 30,
                            "Kein WLAN": 30,
                            "Kein Ton": 30,
                            "Kein Service": 30,
                            "Kein Strom": 30,
                            "Kein Laden": 30,
                            "Kein Bild": 30,
                            "Keine Rückkamera": 30,
                            "Keine Frontkamera": 30,
                            "Kein Mikrofon": 30,
                            "Kein Ohrlautsprecher": 30,
                            "Kein Lautsprecher": 30,
                            "Keine Vibration": 30,
                        },
                        "iPad Air 5 Wi-Fi + Cellular":{
                            "Bildschirm kaputt": 50,
                            "Wasserschaden": 80,
                            "Batteriewechsel": 30,
                            "Ladeanschluss": 30,
                            "Kameraaustausch": 30,
                            "Kopfhöreranschluss": 30,
                            "Home-Taste": 30,
                            "Power-Taste": 30,
                            "Lautstärketaste": 30,
                            "Vibratoraustausch": 30,
                            "Ohrlautsprecher-Austausch": 30,
                            "Lautsprecheraustausch": 30,
                            "Mikrofonaustausch": 30,
                            "Austausch der hinteren Abdeckung": 30,
                            "Ersatz des SIM-Kartenlesers": 30,
                            "Softwareprobleme": 30,
                            "Entsperren": 30,
                            "Flüssigkeitsschaden": 30,
                            "Datenwiederherstellung": 30,
                            "Motherboard-Reparatur": 30,
                            "Kein Einschalten": 30,
                            "Kein Signal": 30,
                            "Keine Hintergrundbeleuchtung": 30,
                            "Keine Anzeige": 30,
                            "Keine Berührung": 30,
                            "Kein WLAN": 30,
                            "Kein Ton": 30,
                            "Kein Service": 30,
                            "Kein Strom": 30,
                            "Kein Laden": 30,
                            "Kein Bild": 30,
                            "Keine Rückkamera": 30,
                            "Keine Frontkamera": 30,
                            "Kein Mikrofon": 30,
                            "Kein Ohrlautsprecher": 30,
                            "Kein Lautsprecher": 30,
                            "Keine Vibration": 30,
                        },
            },
        "iPad Pro 10,5":{
                            "iPad Pro 10,5 Wi-Fi":{
                                "Bildschirm kaputt": 50,
                                "Wasserschaden": 80,
                                "Batteriewechsel": 30,
                                "Ladeanschluss": 30,
                                "Kameraaustausch": 30,
                                "Kopfhöreranschluss": 30,
                                "Home-Taste": 30,
                                "Power-Taste": 30,
                                "Lautstärketaste": 30,
                                "Vibratoraustausch": 30,
                                "Ohrlautsprecher-Austausch": 30,
                                "Lautsprecheraustausch": 30,
                                "Mikrofonaustausch": 30,
                                "Austausch der hinteren Abdeckung": 30,
                                "Ersatz des SIM-Kartenlesers": 30,
                                "Softwareprobleme": 30,
                                "Entsperren": 30,
                                "Flüssigkeitsschaden": 30,
                                "Datenwiederherstellung": 30,
                                "Motherboard-Reparatur": 30,
                                "Kein Einschalten": 30,
                                "Kein Signal": 30,
                                "Keine Hintergrundbeleuchtung": 30,
                                "Keine Anzeige": 30,
                                "Keine Berührung": 30,
                                "Kein WLAN": 30,
                                "Kein Ton": 30,
                                "Kein Service": 30,
                                "Kein Strom": 30,
                                "Kein Laden": 30,
                                "Kein Bild": 30,
                                "Keine Rückkamera": 30,
                                "Keine Frontkamera": 30,
                                "Kein Mikrofon": 30,
                                "Kein Ohrlautsprecher": 30,
                                "Kein Lautsprecher": 30,
                                "Keine Vibration": 30,
                            },
                            "iPad Pro 10,5 Wi-Fi + Cellular":{
                                "Bildschirm kaputt": 50,
                                "Wasserschaden": 80,
                                "Batteriewechsel": 30,
                                "Ladeanschluss": 30,
                                "Kameraaustausch": 30,
                                "Kopfhöreranschluss": 30,
                                "Home-Taste": 30,
                                "Power-Taste": 30,
                                "Lautstärketaste": 30,
                                "Vibratoraustausch": 30,
                                "Ohrlautsprecher-Austausch": 30,
                                "Lautsprecheraustausch": 30,
                                "Mikrofonaustausch": 30,
                                "Austausch der hinteren Abdeckung": 30,
                                "Ersatz des SIM-Kartenlesers": 30,
                                "Softwareprobleme": 30,
                                "Entsperren": 30,
                                "Flüssigkeitsschaden": 30,
                                "Datenwiederherstellung": 30,
                                "Motherboard-Reparatur": 30,
                                "Kein Einschalten": 30,
                                "Kein Signal": 30,
                                "Keine Hintergrundbeleuchtung": 30,
                                "Keine Anzeige": 30,
                                "Keine Berührung": 30,
                                "Kein WLAN": 30,
                                "Kein Ton": 30,
                                "Kein Service": 30,
                                "Kein Strom": 30,
                                "Kein Laden": 30,
                                "Kein Bild": 30,
                                "Keine Rückkamera": 30,
                                "Keine Frontkamera": 30,
                                "Kein Mikrofon": 30,
                                "Kein Ohrlautsprecher": 30,
                                "Kein Lautsprecher": 30,
                                "Keine Vibration": 30,
                            },
            },
        "iPad Pro 11":{
                                "iPad Pro 11 Wi-Fi":{
                                    "Bildschirm kaputt": 50,
                                    "Wasserschaden": 80,
                                    "Batteriewechsel": 30,
                                    "Ladeanschluss": 30,
                                    "Kameraaustausch": 30,
                                    "Kopfhöreranschluss": 30,
                                    "Home-Taste": 30,
                                    "Power-Taste": 30,
                                    "Lautstärketaste": 30,
                                    "Vibratoraustausch": 30,
                                    "Ohrlautsprecher-Austausch": 30,
                                    "Lautsprecheraustausch": 30,
                                    "Mikrofonaustausch": 30,
                                    "Austausch der hinteren Abdeckung": 30,
                                    "Ersatz des SIM-Kartenlesers": 30,
                                    "Softwareprobleme": 30,
                                    "Entsperren": 30,
                                    "Flüssigkeitsschaden": 30,
                                    "Datenwiederherstellung": 30,
                                    "Motherboard-Reparatur": 30,
                                    "Kein Einschalten": 30,
                                    "Kein Signal": 30,
                                    "Keine Hintergrundbeleuchtung": 30,
                                    "Keine Anzeige": 30,
                                    "Keine Berührung": 30,
                                    "Kein WLAN": 30,
                                    "Kein Ton": 30,
                                    "Kein Service": 30,
                                    "Kein Strom": 30,
                                    "Kein Laden": 30,
                                    "Kein Bild": 30,
                                    "Keine Rückkamera": 30,
                                    "Keine Frontkamera": 30,
                                    "Kein Mikrofon": 30,
                                    "Kein Ohrlautsprecher": 30,
                                    "Kein Lautsprecher": 30,
                                    "Keine Vibration": 30,
                                },
                                "iPad Pro 11 Wi-Fi + Cellular":{
                                    "Bildschirm kaputt": 50,
                                    "Wasserschaden": 80,
                                    "Batteriewechsel": 30,
                                    "Ladeanschluss": 30,
                                    "Kameraaustausch": 30,
                                    "Kopfhöreranschluss": 30,
                                    "Home-Taste": 30,
                                    "Power-Taste": 30,
                                    "Lautstärketaste": 30,
                                    "Vibratoraustausch": 30,
                                    "Ohrlautsprecher-Austausch": 30,
                                    "Lautsprecheraustausch": 30,
                                    "Mikrofonaustausch": 30,
                                    "Austausch der hinteren Abdeckung": 30,
                                    "Ersatz des SIM-Kartenlesers": 30,
                                    "Softwareprobleme": 30,
                                    "Entsperren": 30,
                                    "Flüssigkeitsschaden": 30,
                                    "Datenwiederherstellung": 30,
                                    "Motherboard-Reparatur": 30,
                                    "Kein Einschalten": 30,
                                    "Kein Signal": 30,
                                    "Keine Hintergrundbeleuchtung": 30,
                                    "Keine Anzeige": 30,
                                    "Keine Berührung": 30,
                                    "Kein WLAN": 30,
                                    "Kein Ton": 30,
                                    "Kein Service": 30,
                                    "Kein Strom": 30,
                                    "Kein Laden": 30,
                                    "Kein Bild": 30,
                                    "Keine Rückkamera": 30,
                                    "Keine Frontkamera": 30,
                                    "Kein Mikrofon": 30,
                                    "Kein Ohrlautsprecher": 30,
                                    "Kein Lautsprecher": 30,
                                    "Keine Vibration": 30,
                                },
                                "iPad Pro 11 Wi-Fi (2. Gen.)":{
                                    "Bildschirm kaputt": 50,
                                    "Wasserschaden": 80,
                                    "Batteriewechsel": 30,
                                    "Ladeanschluss": 30,
                                    "Kameraaustausch": 30,
                                    "Kopfhöreranschluss": 30,
                                    "Home-Taste": 30,
                                    "Power-Taste": 30,
                                    "Lautstärketaste": 30,
                                    "Vibratoraustausch": 30,
                                    "Ohrlautsprecher-Austausch": 30,
                                    "Lautsprecheraustausch": 30,
                                    "Mikrofonaustausch": 30,
                                    "Austausch der hinteren Abdeckung": 30,
                                    "Ersatz des SIM-Kartenlesers": 30,
                                    "Softwareprobleme": 30,
                                    "Entsperren": 30,
                                    "Flüssigkeitsschaden": 30,
                                    "Datenwiederherstellung": 30,
                                    "Motherboard-Reparatur": 30,
                                    "Kein Einschalten": 30,
                                    "Kein Signal": 30,
                                    "Keine Hintergrundbeleuchtung": 30,
                                    "Keine Anzeige": 30,
                                    "Keine Berührung": 30,
                                    "Kein WLAN": 30,
                                    "Kein Ton": 30,
                                    "Kein Service": 30,
                                    "Kein Strom": 30,
                                    "Kein Laden": 30,
                                    "Kein Bild": 30,
                                    "Keine Rückkamera": 30,
                                    "Keine Frontkamera": 30,
                                    "Kein Mikrofon": 30,
                                    "Kein Ohrlautsprecher": 30,
                                    "Kein Lautsprecher": 30,
                                    "Keine Vibration": 30,
                                },
                                "iPad Pro 11 Wi-Fi + Cellular (2. Gen.)":{
                                    "Bildschirm kaputt": 50,
                                    "Wasserschaden": 80,
                                    "Batteriewechsel": 30,
                                    "Ladeanschluss": 30,
                                    "Kameraaustausch": 30,
                                    "Kopfhöreranschluss": 30,
                                    "Home-Taste": 30,
                                    "Power-Taste": 30,
                                    "Lautstärketaste": 30,
                                    "Vibratoraustausch": 30,
                                    "Ohrlautsprecher-Austausch": 30,
                                    "Lautsprecheraustausch": 30,
                                    "Mikrofonaustausch": 30,
                                    "Austausch der hinteren Abdeckung": 30,
                                    "Ersatz des SIM-Kartenlesers": 30,
                                    "Softwareprobleme": 30,
                                    "Entsperren": 30,
                                    "Flüssigkeitsschaden": 30,
                                    "Datenwiederherstellung": 30,
                                    "Motherboard-Reparatur": 30,
                                    "Kein Einschalten": 30,
                                    "Kein Signal": 30,
                                    "Keine Hintergrundbeleuchtung": 30,
                                    "Keine Anzeige": 30,
                                    "Keine Berührung": 30,
                                    "Kein WLAN": 30,
                                    "Kein Ton": 30,
                                    "Kein Service": 30,
                                    "Kein Strom": 30,
                                    "Kein Laden": 30,
                                    "Kein Bild": 30,
                                    "Keine Rückkamera": 30,
                                    "Keine Frontkamera": 30,
                                    "Kein Mikrofon": 30,
                                    "Kein Ohrlautsprecher": 30,
                                    "Kein Lautsprecher": 30,
                                    "Keine Vibration": 30,
                                },
                                "iPad Pro 11 Wi-Fi (3. Gen.)":{
                                    "Bildschirm kaputt": 50,
                                    "Wasserschaden": 80,
                                    "Batteriewechsel": 30,
                                    "Ladeanschluss": 30,
                                    "Kameraaustausch": 30,
                                    "Kopfhöreranschluss": 30,
                                    "Home-Taste": 30,
                                    "Power-Taste": 30,
                                    "Lautstärketaste": 30,
                                    "Vibratoraustausch": 30,
                                    "Ohrlautsprecher-Austausch": 30,
                                    "Lautsprecheraustausch": 30,
                                    "Mikrofonaustausch": 30,
                                    "Austausch der hinteren Abdeckung": 30,
                                    "Ersatz des SIM-Kartenlesers": 30,
                                    "Softwareprobleme": 30,
                                    "Entsperren": 30,
                                    "Flüssigkeitsschaden": 30,
                                    "Datenwiederherstellung": 30,
                                    "Motherboard-Reparatur": 30,
                                    "Kein Einschalten": 30,
                                    "Kein Signal": 30,
                                    "Keine Hintergrundbeleuchtung": 30,
                                    "Keine Anzeige": 30,
                                    "Keine Berührung": 30,
                                    "Kein WLAN": 30,
                                    "Kein Ton": 30,
                                    "Kein Service": 30,
                                    "Kein Strom": 30,
                                    "Kein Laden": 30,
                                    "Kein Bild": 30,
                                    "Keine Rückkamera": 30,
                                    "Keine Frontkamera": 30,
                                    "Kein Mikrofon": 30,
                                    "Kein Ohrlautsprecher": 30,
                                    "Kein Lautsprecher": 30,
                                    "Keine Vibration": 30,
                                },
                                "iPad Pro 11 Wi-Fi + Cellular (3. Gen.)":{
                                    "Bildschirm kaputt": 50,
                                    "Wasserschaden": 80,
                                    "Batteriewechsel": 30,
                                    "Ladeanschluss": 30,
                                    "Kameraaustausch": 30,
                                    "Kopfhöreranschluss": 30,
                                    "Home-Taste": 30,
                                    "Power-Taste": 30,
                                    "Lautstärketaste": 30,
                                    "Vibratoraustausch": 30,
                                    "Ohrlautsprecher-Austausch": 30,
                                    "Lautsprecheraustausch": 30,
                                    "Mikrofonaustausch": 30,
                                    "Austausch der hinteren Abdeckung": 30,
                                    "Ersatz des SIM-Kartenlesers": 30,
                                    "Softwareprobleme": 30,
                                    "Entsperren": 30,
                                    "Flüssigkeitsschaden": 30,
                                    "Datenwiederherstellung": 30,
                                    "Motherboard-Reparatur": 30,
                                    "Kein Einschalten": 30,
                                    "Kein Signal": 30,
                                    "Keine Hintergrundbeleuchtung": 30,
                                    "Keine Anzeige": 30,
                                    "Keine Berührung": 30,
                                    "Kein WLAN": 30,
                                    "Kein Ton": 30,
                                    "Kein Service": 30,
                                    "Kein Strom": 30,
                                    "Kein Laden": 30,
                                    "Kein Bild": 30,
                                    "Keine Rückkamera": 30,
                                    "Keine Frontkamera": 30,
                                    "Kein Mikrofon": 30,
                                    "Kein Ohrlautsprecher": 30,
                                    "Kein Lautsprecher": 30,
                                    "Keine Vibration": 30,
                                },
                                "iPad Pro 11 Wi-Fi (4. Gen.)":{
                                    "Bildschirm kaputt": 50,
                                    "Wasserschaden": 80,
                                    "Batteriewechsel": 30,
                                    "Ladeanschluss": 30,
                                    "Kameraaustausch": 30,
                                    "Kopfhöreranschluss": 30,
                                    "Home-Taste": 30,
                                    "Power-Taste": 30,
                                    "Lautstärketaste": 30,
                                    "Vibratoraustausch": 30,
                                    "Ohrlautsprecher-Austausch": 30,
                                    "Lautsprecheraustausch": 30,
                                    "Mikrofonaustausch": 30,
                                    "Austausch der hinteren Abdeckung": 30,
                                    "Ersatz des SIM-Kartenlesers": 30,
                                    "Softwareprobleme": 30,
                                    "Entsperren": 30,
                                    "Flüssigkeitsschaden": 30,
                                    "Datenwiederherstellung": 30,
                                    "Motherboard-Reparatur": 30,
                                    "Kein Einschalten": 30,
                                    "Kein Signal": 30,
                                    "Keine Hintergrundbeleuchtung": 30,
                                    "Keine Anzeige": 30,
                                    "Keine Berührung": 30,
                                    "Kein WLAN": 30,
                                    "Kein Ton": 30,
                                    "Kein Service": 30,
                                    "Kein Strom": 30,
                                    "Kein Laden": 30,
                                    "Kein Bild": 30,
                                    "Keine Rückkamera": 30,
                                    "Keine Frontkamera": 30,
                                    "Kein Mikrofon": 30,
                                    "Kein Ohrlautsprecher": 30,
                                    "Kein Lautsprecher": 30,
                                    "Keine Vibration": 30,
                                },
                                "iPad Pro 11 Wi-Fi + Cellular (4. Gen.)":{
                                    "Bildschirm kaputt": 50,
                                    "Wasserschaden": 80,
                                    "Batteriewechsel": 30,
                                    "Ladeanschluss": 30,
                                    "Kameraaustausch": 30,
                                    "Kopfhöreranschluss": 30,
                                    "Home-Taste": 30,
                                    "Power-Taste": 30,
                                    "Lautstärketaste": 30,
                                    "Vibratoraustausch": 30,
                                    "Ohrlautsprecher-Austausch": 30,
                                    "Lautsprecheraustausch": 30,
                                    "Mikrofonaustausch": 30,
                                    "Austausch der hinteren Abdeckung": 30,
                                    "Ersatz des SIM-Kartenlesers": 30,
                                    "Softwareprobleme": 30,
                                    "Entsperren": 30,
                                    "Flüssigkeitsschaden": 30,
                                    "Datenwiederherstellung": 30,
                                    "Motherboard-Reparatur": 30,
                                    "Kein Einschalten": 30,
                                    "Kein Signal": 30,
                                    "Keine Hintergrundbeleuchtung": 30,
                                    "Keine Anzeige": 30,
                                    "Keine Berührung": 30,
                                    "Kein WLAN": 30,
                                    "Kein Ton": 30,
                                    "Kein Service": 30,
                                    "Kein Strom": 30,
                                    "Kein Laden": 30,
                                    "Kein Bild": 30,
                                    "Keine Rückkamera": 30,
                                    "Keine Frontkamera": 30,
                                    "Kein Mikrofon": 30,
                                    "Kein Ohrlautsprecher": 30,
                                    "Kein Lautsprecher": 30,
                                    "Keine Vibration": 30,
                                },
            },
        "iPad Pro 12,9":{
                                    "iPad Pro 12,9 Wi-Fi (2. Gen.)":{
                                        "Bildschirm kaputt": 50,
                                        "Wasserschaden": 80,
                                        "Batteriewechsel": 30,
                                        "Ladeanschluss": 30,
                                        "Kameraaustausch": 30,
                                        "Kopfhöreranschluss": 30,
                                        "Home-Taste": 30,
                                        "Power-Taste": 30,
                                        "Lautstärketaste": 30,
                                        "Vibratoraustausch": 30,
                                        "Ohrlautsprecher-Austausch": 30,
                                        "Lautsprecheraustausch": 30,
                                        "Mikrofonaustausch": 30,
                                        "Austausch der hinteren Abdeckung": 30,
                                        "Ersatz des SIM-Kartenlesers": 30,
                                        "Softwareprobleme": 30,
                                        "Entsperren": 30,
                                        "Flüssigkeitsschaden": 30,
                                        "Datenwiederherstellung": 30,
                                        "Motherboard-Reparatur": 30,
                                        "Kein Einschalten": 30,
                                        "Kein Signal": 30,
                                        "Keine Hintergrundbeleuchtung": 30,
                                        "Keine Anzeige": 30,
                                        "Keine Berührung": 30,
                                        "Kein WLAN": 30,
                                        "Kein Ton": 30,
                                        "Kein Service": 30,
                                        "Kein Strom": 30,
                                        "Kein Laden": 30,
                                        "Kein Bild": 30,
                                        "Keine Rückkamera": 30,
                                        "Keine Frontkamera": 30,
                                        "Kein Mikrofon": 30,
                                        "Kein Ohrlautsprecher": 30,
                                        "Kein Lautsprecher": 30,
                                        "Keine Vibration": 30,
                                    },
                                    "iPad Pro 12,9 Wi-Fi + Cellular (2. Gen.)":{
                                        "Bildschirm kaputt": 50,
                                        "Wasserschaden": 80,
                                        "Batteriewechsel": 30,
                                        "Ladeanschluss": 30,
                                        "Kameraaustausch": 30,
                                        "Kopfhöreranschluss": 30,
                                        "Home-Taste": 30,
                                        "Power-Taste": 30,
                                        "Lautstärketaste": 30,
                                        "Vibratoraustausch": 30,
                                        "Ohrlautsprecher-Austausch": 30,
                                        "Lautsprecheraustausch": 30,
                                        "Mikrofonaustausch": 30,
                                        "Austausch der hinteren Abdeckung": 30,
                                        "Ersatz des SIM-Kartenlesers": 30,
                                        "Softwareprobleme": 30,
                                        "Entsperren": 30,
                                        "Flüssigkeitsschaden": 30,
                                        "Datenwiederherstellung": 30,
                                        "Motherboard-Reparatur": 30,
                                        "Kein Einschalten": 30,
                                        "Kein Signal": 30,
                                        "Keine Hintergrundbeleuchtung": 30,
                                        "Keine Anzeige": 30,
                                        "Keine Berührung": 30,
                                        "Kein WLAN": 30,
                                        "Kein Ton": 30,
                                        "Kein Service": 30,
                                        "Kein Strom": 30,
                                        "Kein Laden": 30,
                                        "Kein Bild": 30,
                                        "Keine Rückkamera": 30,
                                        "Keine Frontkamera": 30,
                                        "Kein Mikrofon": 30,
                                        "Kein Ohrlautsprecher": 30,
                                        "Kein Lautsprecher": 30,
                                        "Keine Vibration": 30,
                                    },
                                    "iPad Pro 12,9 Wi-Fi (3. Gen.)":{
                                        "Bildschirm kaputt": 50,
                                        "Wasserschaden": 80,
                                        "Batteriewechsel": 30,
                                        "Ladeanschluss": 30,
                                        "Kameraaustausch": 30,
                                        "Kopfhöreranschluss": 30,
                                        "Home-Taste": 30,
                                        "Power-Taste": 30,
                                        "Lautstärketaste": 30,
                                        "Vibratoraustausch": 30,
                                        "Ohrlautsprecher-Austausch": 30,
                                        "Lautsprecheraustausch": 30,
                                        "Mikrofonaustausch": 30,
                                        "Austausch der hinteren Abdeckung": 30,
                                        "Ersatz des SIM-Kartenlesers": 30,
                                        "Softwareprobleme": 30,
                                        "Entsperren": 30,
                                        "Flüssigkeitsschaden": 30,
                                        "Datenwiederherstellung": 30,
                                        "Motherboard-Reparatur": 30,
                                        "Kein Einschalten": 30,
                                        "Kein Signal": 30,
                                        "Keine Hintergrundbeleuchtung": 30,
                                        "Keine Anzeige": 30,
                                        "Keine Berührung": 30,
                                        "Kein WLAN": 30,
                                        "Kein Ton": 30,
                                        "Kein Service": 30,
                                        "Kein Strom": 30,
                                        "Kein Laden": 30,
                                        "Kein Bild": 30,
                                        "Keine Rückkamera": 30,
                                        "Keine Frontkamera": 30,
                                        "Kein Mikrofon": 30,
                                        "Kein Ohrlautsprecher": 30,
                                        "Kein Lautsprecher": 30,
                                        "Keine Vibration": 30,
                                    },
                                    "iPad Pro 12,9 Wi-Fi + Cellular (3. Gen.)":{
                                        "Bildschirm kaputt": 50,
                                        "Wasserschaden": 80,
                                        "Batteriewechsel": 30,
                                        "Ladeanschluss": 30,
                                        "Kameraaustausch": 30,
                                        "Kopfhöreranschluss": 30,
                                        "Home-Taste": 30,
                                        "Power-Taste": 30,
                                        "Lautstärketaste": 30,
                                        "Vibratoraustausch": 30,
                                        "Ohrlautsprecher-Austausch": 30,
                                        "Lautsprecheraustausch": 30,
                                        "Mikrofonaustausch": 30,
                                        "Austausch der hinteren Abdeckung": 30,
                                        "Ersatz des SIM-Kartenlesers": 30,
                                        "Softwareprobleme": 30,
                                        "Entsperren": 30,
                                        "Flüssigkeitsschaden": 30,
                                        "Datenwiederherstellung": 30,
                                        "Motherboard-Reparatur": 30,
                                        "Kein Einschalten": 30,
                                        "Kein Signal": 30,
                                        "Keine Hintergrundbeleuchtung": 30,
                                        "Keine Anzeige": 30,
                                        "Keine Berührung": 30,
                                        "Kein WLAN": 30,
                                        "Kein Ton": 30,
                                        "Kein Service": 30,
                                        "Kein Strom": 30,
                                        "Kein Laden": 30,
                                        "Kein Bild": 30,
                                        "Keine Rückkamera": 30,
                                        "Keine Frontkamera": 30,
                                        "Kein Mikrofon": 30,
                                        "Kein Ohrlautsprecher": 30,
                                        "Kein Lautsprecher": 30,
                                        "Keine Vibration": 30,
                                    },
                                    "iPad Pro 12,9 Wi-Fi (4. Gen.)":{
                                        "Bildschirm kaputt": 50,
                                        "Wasserschaden": 80,
                                        "Batteriewechsel": 30,
                                        "Ladeanschluss": 30,
                                        "Kameraaustausch": 30,
                                        "Kopfhöreranschluss": 30,
                                        "Home-Taste": 30,
                                        "Power-Taste": 30,
                                        "Lautstärketaste": 30,
                                        "Vibratoraustausch": 30,
                                        "Ohrlautsprecher-Austausch": 30,
                                        "Lautsprecheraustausch": 30,
                                        "Mikrofonaustausch": 30,
                                        "Austausch der hinteren Abdeckung": 30,
                                        "Ersatz des SIM-Kartenlesers": 30,
                                        "Softwareprobleme": 30,
                                        "Entsperren": 30,
                                        "Flüssigkeitsschaden": 30,
                                        "Datenwiederherstellung": 30,
                                        "Motherboard-Reparatur": 30,
                                        "Kein Einschalten": 30,
                                        "Kein Signal": 30,
                                        "Keine Hintergrundbeleuchtung": 30,
                                        "Keine Anzeige": 30,
                                        "Keine Berührung": 30,
                                        "Kein WLAN": 30,
                                        "Kein Ton": 30,
                                        "Kein Service": 30,
                                        "Kein Strom": 30,
                                        "Kein Laden": 30,
                                        "Kein Bild": 30,
                                        "Keine Rückkamera": 30,
                                        "Keine Frontkamera": 30,
                                        "Kein Mikrofon": 30,
                                        "Kein Ohrlautsprecher": 30,
                                        "Kein Lautsprecher": 30,
                                        "Keine Vibration": 30,
                                    },
                                    "iPad Pro 12,9 Wi-Fi + Cellular (4. Gen.)":{
                                        "Bildschirm kaputt": 50,
                                        "Wasserschaden": 80,
                                        "Batteriewechsel": 30,
                                        "Ladeanschluss": 30,
                                        "Kameraaustausch": 30,
                                        "Kopfhöreranschluss": 30,
                                        "Home-Taste": 30,
                                        "Power-Taste": 30,
                                        "Lautstärketaste": 30,
                                        "Vibratoraustausch": 30,
                                        "Ohrlautsprecher-Austausch": 30,
                                        "Lautsprecheraustausch": 30,
                                        "Mikrofonaustausch": 30,
                                        "Austausch der hinteren Abdeckung": 30,
                                        "Ersatz des SIM-Kartenlesers": 30,
                                        "Softwareprobleme": 30,
                                        "Entsperren": 30,
                                        "Flüssigkeitsschaden": 30,
                                        "Datenwiederherstellung": 30,
                                        "Motherboard-Reparatur": 30,
                                        "Kein Einschalten": 30,
                                        "Kein Signal": 30,
                                        "Keine Hintergrundbeleuchtung": 30,
                                        "Keine Anzeige": 30,
                                        "Keine Berührung": 30,
                                        "Kein WLAN": 30,
                                        "Kein Ton": 30,
                                        "Kein Service": 30,
                                        "Kein Strom": 30,
                                        "Kein Laden": 30,
                                        "Kein Bild": 30,
                                        "Keine Rückkamera": 30,
                                        "Keine Frontkamera": 30,
                                        "Kein Mikrofon": 30,
                                        "Kein Ohrlautsprecher": 30,
                                        "Kein Lautsprecher": 30,
                                        "Keine Vibration": 30,
                                    },
                                    "iPad Pro 12,9 Wi-Fi (5. Gen.)":{
                                        "Bildschirm kaputt": 50,
                                        "Wasserschaden": 80,
                                        "Batteriewechsel": 30,
                                        "Ladeanschluss": 30,
                                        "Kameraaustausch": 30,
                                        "Kopfhöreranschluss": 30,
                                        "Home-Taste": 30,
                                        "Power-Taste": 30,
                                        "Lautstärketaste": 30,
                                        "Vibratoraustausch": 30,
                                        "Ohrlautsprecher-Austausch": 30,
                                        "Lautsprecheraustausch": 30,
                                        "Mikrofonaustausch": 30,
                                        "Austausch der hinteren Abdeckung": 30,
                                        "Ersatz des SIM-Kartenlesers": 30,
                                        "Softwareprobleme": 30,
                                        "Entsperren": 30,
                                        "Flüssigkeitsschaden": 30,
                                        "Datenwiederherstellung": 30,
                                        "Motherboard-Reparatur": 30,
                                        "Kein Einschalten": 30,
                                        "Kein Signal": 30,
                                        "Keine Hintergrundbeleuchtung": 30,
                                        "Keine Anzeige": 30,
                                        "Keine Berührung": 30,
                                        "Kein WLAN": 30,
                                        "Kein Ton": 30,
                                        "Kein Service": 30,
                                        "Kein Strom": 30,
                                        "Kein Laden": 30,
                                        "Kein Bild": 30,
                                        "Keine Rückkamera": 30,
                                        "Keine Frontkamera": 30,
                                        "Kein Mikrofon": 30,
                                        "Kein Ohrlautsprecher": 30,
                                        "Kein Lautsprecher": 30,
                                        "Keine Vibration": 30,
                                    },
                                    "iPad Pro 12,9 Wi-Fi + Cellular (5. Gen.)":{
                                        "Bildschirm kaputt": 50,
                                        "Wasserschaden": 80,
                                        "Batteriewechsel": 30,
                                        "Ladeanschluss": 30,
                                        "Kameraaustausch": 30,
                                        "Kopfhöreranschluss": 30,
                                        "Home-Taste": 30,
                                        "Power-Taste": 30,
                                        "Lautstärketaste": 30,
                                        "Vibratoraustausch": 30,
                                        "Ohrlautsprecher-Austausch": 30,
                                        "Lautsprecheraustausch": 30,
                                        "Mikrofonaustausch": 30,
                                        "Austausch der hinteren Abdeckung": 30,
                                        "Ersatz des SIM-Kartenlesers": 30,
                                        "Softwareprobleme": 30,
                                        "Entsperren": 30,
                                        "Flüssigkeitsschaden": 30,
                                        "Datenwiederherstellung": 30,
                                        "Motherboard-Reparatur": 30,
                                        "Kein Einschalten": 30,
                                        "Kein Signal": 30,
                                        "Keine Hintergrundbeleuchtung": 30,
                                        "Keine Anzeige": 30,
                                        "Keine Berührung": 30,
                                        "Kein WLAN": 30,
                                        "Kein Ton": 30,
                                        "Kein Service": 30,
                                        "Kein Strom": 30,
                                        "Kein Laden": 30,
                                        "Kein Bild": 30,
                                        "Keine Rückkamera": 30,
                                        "Keine Frontkamera": 30,
                                        "Kein Mikrofon": 30,
                                        "Kein Ohrlautsprecher": 30,
                                        "Kein Lautsprecher": 30,
                                        "Keine Vibration": 30,
                                    },
                                    "iPad Pro 12,9 Wi-Fi (6. Gen.)":{
                                        "Bildschirm kaputt": 50,
                                        "Wasserschaden": 80,
                                        "Batteriewechsel": 30,
                                        "Ladeanschluss": 30,
                                        "Kameraaustausch": 30,
                                        "Kopfhöreranschluss": 30,
                                        "Home-Taste": 30,
                                        "Power-Taste": 30,
                                        "Lautstärketaste": 30,
                                        "Vibratoraustausch": 30,
                                        "Ohrlautsprecher-Austausch": 30,
                                        "Lautsprecheraustausch": 30,
                                        "Mikrofonaustausch": 30,
                                        "Austausch der hinteren Abdeckung": 30,
                                        "Ersatz des SIM-Kartenlesers": 30,
                                        "Softwareprobleme": 30,
                                        "Entsperren": 30,
                                        "Flüssigkeitsschaden": 30,
                                        "Datenwiederherstellung": 30,
                                        "Motherboard-Reparatur": 30,
                                        "Kein Einschalten": 30,
                                        "Kein Signal": 30,
                                        "Keine Hintergrundbeleuchtung": 30,
                                        "Keine Anzeige": 30,
                                        "Keine Berührung": 30,
                                        "Kein WLAN": 30,
                                        "Kein Ton": 30,
                                        "Kein Service": 30,
                                        "Kein Strom": 30,
                                        "Kein Laden": 30,
                                        "Kein Bild": 30,
                                        "Keine Rückkamera": 30,
                                        "Keine Frontkamera": 30,
                                        "Kein Mikrofon": 30,
                                        "Kein Ohrlautsprecher": 30,
                                        "Kein Lautsprecher": 30,
                                        "Keine Vibration": 30,
                                    },
                                    "iPad Pro 12,9 Wi-Fi + Cellular (6. Gen.)":{
                                        "Bildschirm kaputt": 50,
                                        "Wasserschaden": 80,
                                        "Batteriewechsel": 30,
                                        "Ladeanschluss": 30,
                                        "Kameraaustausch": 30,
                                        "Kopfhöreranschluss": 30,
                                        "Home-Taste": 30,
                                        "Power-Taste": 30,
                                        "Lautstärketaste": 30,
                                        "Vibratoraustausch": 30,
                                        "Ohrlautsprecher-Austausch": 30,
                                        "Lautsprecheraustausch": 30,
                                        "Mikrofonaustausch": 30,
                                        "Austausch der hinteren Abdeckung": 30,
                                        "Ersatz des SIM-Kartenlesers": 30,
                                        "Softwareprobleme": 30,
                                        "Entsperren": 30,
                                        "Flüssigkeitsschaden": 30,
                                        "Datenwiederherstellung": 30,
                                        "Motherboard-Reparatur": 30,
                                        "Kein Einschalten": 30,
                                        "Kein Signal": 30,
                                        "Keine Hintergrundbeleuchtung": 30,
                                        "Keine Anzeige": 30,
                                        "Keine Berührung": 30,
                                        "Kein WLAN": 30,
                                        "Kein Ton": 30,
                                        "Kein Service": 30,
                                        "Kein Strom": 30,
                                        "Kein Laden": 30,
                                        "Kein Bild": 30,
                                        "Keine Rückkamera": 30,
                                        "Keine Frontkamera": 30,
                                        "Kein Mikrofon": 30,
                                        "Kein Ohrlautsprecher": 30,
                                        "Kein Lautsprecher": 30,
                                        "Keine Vibration": 30,
                                    },
            }
    }
}

{/**


      },
    "IPAD": {
      "iPad 2": {
        "Bildschirm kaputt": 50,
        "Wasserschaden": 80,
        "Batteriewechsel": 30,
        "Ladeanschluss": 30,
        "Kameraaustausch": 30,
        "Kopfhöreranschluss": 30,
        "Home-Taste": 30,
        "Power-Taste": 30,
        "Lautstärketaste": 30,
        "Vibratoraustausch": 30,
        "Ohrlautsprecher-Austausch": 30,
        "Lautsprecheraustausch": 30,
        "Mikrofonaustausch": 30,
        "Austausch der hinteren Abdeckung": 30,
        "Ersatz des SIM-Kartenlesers": 30,
        "Softwareprobleme": 30,
        "Entsperren": 30,
        "Flüssigkeitsschaden": 30,
        "Datenwiederherstellung": 30,
        "Motherboard-Reparatur": 30,
        "Kein Einschalten": 30,
        "Kein Signal": 30,
        "Keine Hintergrundbeleuchtung": 30,
        "Keine Anzeige": 30,
        "Keine Berührung": 30,
        "Kein WLAN": 30,
        "Kein Ton": 30,
        "Kein Service": 30,
        "Kein Strom": 30,
        "Kein Laden": 30,
        "Kein Bild": 30,
        "Keine Rückkamera": 30,
        "Keine Frontkamera": 30,
        "Kein Mikrofon": 30,
        "Kein Ohrlautsprecher": 30,
        "Kein Lautsprecher": 30,
        "Keine Vibration": 30,
      },
        "iPad 3": {
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPad 4": {
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "iPad 5": {
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        

    },
    "MacBook": {
        "MacBook Air": {
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        "MacBook Pro": {
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30, 
        },
        "MacBook Pro 13\" 2020": {
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30, 
            "Keine Vibration": 30,
        },

        },
    "MusicElectronics": {
        "Apple Watch": {
            "Bildschirm kaputt": 50,
            "Wasserschaden": 80,
            "Batteriewechsel": 30,
            "Ladeanschluss": 30,
            "Kameraaustausch": 30,
            "Kopfhöreranschluss": 30,
            "Home-Taste": 30,
            "Power-Taste": 30,
            "Lautstärketaste": 30,
            "Vibratoraustausch": 30,
            "Ohrlautsprecher-Austausch": 30,
            "Lautsprecheraustausch": 30,
            "Mikrofonaustausch": 30,
            "Austausch der hinteren Abdeckung": 30,
            "Ersatz des SIM-Kartenlesers": 30,
            "Softwareprobleme": 30,
            "Entsperren": 30,
            "Flüssigkeitsschaden": 30,
            "Datenwiederherstellung": 30,
            "Motherboard-Reparatur": 30,
            "Kein Einschalten": 30,
            "Kein Signal": 30,
            "Keine Hintergrundbeleuchtung": 30,
            "Keine Anzeige": 30,
            "Keine Berührung": 30,
            "Kein WLAN": 30,
            "Kein Ton": 30,
            "Kein Service": 30,
            "Kein Strom": 30,
            "Kein Laden": 30,
            "Kein Bild": 30,
            "Keine Rückkamera": 30,
            "Keine Frontkamera": 30,
            "Kein Mikrofon": 30,
            "Kein Ohrlautsprecher": 30,
            "Kein Lautsprecher": 30,
            "Keine Vibration": 30,
        },
        },
*/}

export interface IServicesProps {
}

export interface IServicesState {
    dataFilter: newServiceData;
    selecedDevice: string;
    selectedVersion: string;
    selectedVersionNumber: string;
    selectedService: string;
    prise: number;
    showForm: boolean;
    note:string;
    name:string;
    lastName:string;
    phone:string;
    email:string;
    street:string;
    houseNumber:string;
    zip:string;
    city:string;
    succesMsg:boolean;
    serialNumber:string;


}


export default class Services extends React.Component<IServicesProps, IServicesState> {
  constructor(props: IServicesProps) {
    super(props);

    this.state = {
        dataFilter: servicesData,
        selecedDevice: "",
        selectedVersion: "",
        selectedVersionNumber: "",
        selectedService: "",
        prise: 0,
        showForm: false,
        note:"",
        name:"",
        lastName:"",
        phone:"",
        email:"",
        street:"",
        houseNumber:"",
        zip:"",
        city:"",
        succesMsg:false,
        serialNumber:""


      };
    }
  
private handleSendEmail () : void {
    const {
        selecedDevice,
        selectedVersion,
        selectedVersionNumber,
        selectedService,
        name,
        lastName,
        phone,
        email,
        street,
        houseNumber,
        zip,
        city,
        note,
        serialNumber,

    } = this.state;
    const data = {
        selecedDevice,
        selectedVersion,
        selectedVersionNumber,
        selectedService,
        name,
        lastName,
        phone,
        email,
        street,
        houseNumber,
        zip,
        city,
        note,
        serialNumber,
     

    }
    fetch('https://www.alphanet.co.at/api/send_email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      })
      .then((response) => response.json())
      .then((data) => {
        // if success
        if(data.success){
          this.setState({
            name: "",
            lastName: "",
            phone: "",
            email: "",
            street: "",
            houseNumber: "",
            zip: "",
            city: "",
            note: "",
            serialNumber: "",
            showForm: false,
            
           })
        }
      }
      )
      .catch((error) => {
        console.error('Error:', error);
      });
      this.setState({ succesMsg: true });

      window.scrollTo(0, 0); 

  }


    componentDidUpdate(prevProps: Readonly<IServicesProps>, prevState: Readonly<IServicesState>, snapshot?: any): void {

    }

    componentDidMount(): void {
    }
  
  public render() {
    return (
  <div className="">
        <Banner isHome={false} pageName="Services" color="white"/>
        <div className=' flex flex-col text-gray-800 py-3 px-3 xl:px-10 xl:py-10 lg:px-10 lg:py-10 md:px-8 md:py-8'>
            <h1 className="xl:text-5xl lg:text-3xl md:text-2xl sm:text-base font-bold text-gray-800">Wie viel kostet meine Reparatur?</h1>
        </div>
            <div className="flex flex-wrap gap-6 mx-auto text-center justify-center w-full  sm:w-2/3 md:w-full lg:w-full xl:w-3/4">
          {
            this.state.selecedDevice === "" ?
          Object.keys(this.state.dataFilter).map((category) => (
            
            <CardImage key={category} name={category} isWithimg={true}
            onClick={() => {
                this.setState({selecedDevice: category});
                window.scrollTo({ top: 500, behavior: 'smooth' });
            }}/>
            ))
            :
            <CardImage name={this.state.selecedDevice} isWithimg={true}
            onClick={() => {
                this.setState({selecedDevice: "", selectedVersion: "",selectedVersionNumber:"", selectedService: "", prise: 0, showForm: false});
            }}/>
            }
            </div>
            <div className="flex flex-wrap gap-6 mx-auto text-center justify-center w-full p-10 sm:w-5/6 xl:w-3/4">

          {
            this.state.selecedDevice !== "" ?
            this.state.selectedVersion === "" ?

          Object.keys(this.state.dataFilter[this.state.selecedDevice]).map((version) => (
            
            <CardImage key={version} name={version} isWithimg={true}
            onClick={() => {
                this.setState({selectedVersion: version});
                window.scrollTo({ top: 600, behavior: 'smooth' });
            }}/>
            ))
            :
            <CardImage name={this.state.selectedVersion} isWithimg={true}
            onClick={() => {
                this.setState({selectedVersion: "",selectedVersionNumber:"", selectedService: "", prise: 0, showForm: false});
                window.scrollTo({ top: 200, behavior: 'smooth' });

            }}/>
            :
            <></>
            }
            </div>
            <div className="flex flex-wrap gap-6 mx-auto text-center justify-center w-full p-10 sm:w-5/6 xl:w-full">
          {
            this.state.selecedDevice !== "" &&
            this.state.selectedVersion !== "" ?
            this.state.selectedVersionNumber === "" ?


          Object.keys(this.state.dataFilter[this.state.selecedDevice][this.state.selectedVersion]).map((VersionNumber) => (
            
            <CardImage key={VersionNumber} name={VersionNumber} isWithimg={false}
            onClick={() => {
                this.setState({selectedVersionNumber: VersionNumber,
                 });
                window.scrollTo({ top: 800, behavior: 'smooth' });

            }}/>
            ))
            :
            <CardImage name={this.state.selectedVersionNumber} isWithimg={false}
            onClick={() => {
                this.setState({selectedVersionNumber:"", selectedService: "", prise: 0, showForm: false});
            }}/>
            :
            <></>
            }
            </div>

            <div className="flex flex-wrap gap-6 mx-auto text-center justify-center w-full p-10 sm:w-full xl:w-full">
          {
            this.state.selecedDevice !== "" &&
            this.state.selectedVersion !== "" ?
            this.state.selectedVersionNumber !== "" ?
            this.state.selectedService === "" ?
            


          Object.keys(this.state.dataFilter[this.state.selecedDevice][this.state.selectedVersion][this.state.selectedVersionNumber]).map((service) => (
            
            <CardImage key={service} name={service} isWithimg={false}
            onClick={() => {
                this.setState({selectedService: service,
                prise: this.state.dataFilter[this.state.selecedDevice][this.state.selectedVersion][this.state.selectedVersionNumber][service] });
                console.log(service);
                window.scrollTo({ top: 800, behavior: 'smooth' });

            }}/>
            ))
            :
            <CardImage name={this.state.selectedService} isWithimg={false}
            onClick={() => {
                this.setState({selectedService: "", prise: 0, showForm: false});
            }}/>
            :
            <></>
            :
            <></>
            }
            </div>
            {/**show prise */}
            <div className="flex flex-wrap gap-6 mx-auto text-center justify-center w-full p-10 sm:w-5/6 xl:w-3/4">
            {
                this.state.prise!==0 ?
                <div>
                   {/** <h1 className="text-2xl font-bold text-gray-800">Prise ab: {this.state.prise}€</h1> */}
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
                        this.setState({showForm: true});
                        window.scrollTo({ top: 1500, behavior: 'smooth' });

                    }}>Kontakt</button>
                </div>
                :
                <></>
            }
            </div>

            {/**show form */}
            <div className="flex flex-wrap gap-6 mx-auto text-center justify-center w-full p-10 sm:w-5/6 xl:w-3/4">
            {
                this.state.showForm ?
                <div className="items-center justify-center w-[90%] m-auto shadow-lg rounded-lg p-4">
                <div className="my-3">
                  <p className="text-center text-2xl font-bold">Adresse and Info</p>
                  <p className="text-center text-gray-500">Bitte füllen Sie alle Felder aus</p>
                  <p className="text-center text-gray-500">* Pflichtfelder</p>

                </div>
                <div>
                  <input type="text"
                  onChange={(e) => this.setState({ name: e.target.value })}
                  placeholder="Name *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                  required
                  />
                </div>
                <div>
                  <input type="text"
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  placeholder="Nachname *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                  />
                </div>
                <div>
                  <input type="text"
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  placeholder="Telefonnummer *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                   />
                </div>
                <div>
                  <input type="mail"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  placeholder="Email *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                  />
                </div>
                <div>
                  <input type="text"
                  onChange={(e) => this.setState({ street: e.target.value })}
                  placeholder="Straße *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                  />
                </div>
                <div>
                  <input type="text"
                  onChange={(e) => this.setState({ houseNumber: e.target.value })}
                  placeholder="Hausnummer *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                  />
                </div>
                <div>
                  <input type="text"
                  onChange={(e) => this.setState({ zip: e.target.value })}
                  placeholder="PLZ *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                  />
                </div>
                <div>
                  <input type="text"
                  onChange={(e) => this.setState({ city: e.target.value })}
                  placeholder="Ort *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                   />
                </div>
                <div>
                  <input type="text"
                  onChange={(e) => this.setState({ serialNumber: e.target.value })}
                  placeholder="Seriennummer *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                   /> 
                <div className="flex lg:flex-1 w-full cursor-pointer my-6 mx-auto lg:gap-6 flex-col">
              
                <button
              disabled={
                this.state.name === '' ||
                this.state.lastName === '' ||
                this.state.phone === '' ||
                this.state.email === '' ||
                this.state.street === '' ||
                this.state.houseNumber === '' ||
                this.state.zip === '' ||
                this.state.city === '' ||
                this.state.serialNumber === ''
              }
              
                          onClick={() => {
                            this.handleSendEmail();
                          

    
                          }}  
                          className="bg-blue-500 text-white font-semibold hover:bg-blue-600 py-2 px-4  hover:border-transparent rounded w-1/2 mx-auto my-3
                          disabled:opacity-50 disabled:cursor-not-allowed">
                            Senden
                      </button>
                 </div>
              </div>
                </div>

               
                :
                <></>
            }
            </div>
            </div>
    );
    }
}