import * as React from 'react';
import { NavLink } from 'react-router-dom';

export interface IPricingTableDetailedAProps {

}

export interface IPricingTableDetailedAState {
}

export default class PricingTableDetailedA extends React.Component<IPricingTableDetailedAProps, IPricingTableDetailedAState> {
  constructor(props: IPricingTableDetailedAProps) {
    super(props);

    this.state = {
    }
    this.handleNavItemClick = this.handleNavItemClick.bind(this);

  }
  handleNavItemClick() {
    this.setState({
      isNavbarTogglerActive: false,
    });
  }
  public render() {
    return (
      <div>
         {/*<!-- Component: Basic Pricing Table with Details --> */}
      <div className="lg:max-md-full mx-auto max-w-sm overflow-hidden rounded bg-white text-slate-500 shadow-lg shadow-slate-200">
        <div className="flex flex-col">
          <header className="flex flex-col gap-6 p-6 text-slate-400">
            <h3 className="text-xl font-bold text-slate-700">
            PAKET A
              <span className="block text-sm font-normal text-slate-400">
              1 Mann + LKW
              </span>
            </h3>
            <h4>
              <span className="text-3xl">€</span>
              <span className="text-5xl font-bold tracking-tighter text-slate-700 transition-all duration-300 lg:text-6xl">
                Ab 60
              </span>
              <span className="text-sm">/Stunde inkl MwSt</span>
            </h4>

            <NavLink to="/FreeOffer">
       <button
       onClick={this.handleNavItemClick} 
       className="inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded bg-red-500 px-6 text-sm font-medium tracking-wide text-white shadow-lg shadow-red-100 transition duration-300 hover:bg-red-600 hover:shadow-md hover:shadow-red-100 focus:bg-red-700 focus:shadow-md focus:shadow-red-100 focus-visible:outline-none">
              <span>Angebot Stellen</span>

         </button>
       </NavLink>
          </header>
          <div className="p-6">
            <ul className="space-y-4">
              <li className="flex items-start gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-6 w-6 shrink-0 p-1 text-red-500"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clipRule="evenodd"
                  />
                </svg>
                1 Mann + LKW (mind. 2h)
              </li>
              <li className="flex items-start gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-6 w-6 shrink-0 p-1 text-red-500"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clipRule="evenodd"
                  />
                </svg>
                Paket A eignet sich für kleinere Übersiedlungen.
              </li>
              <li className="flex items-start gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-6 w-6 shrink-0 p-1 text-red-500"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clipRule="evenodd"
                  />
                </svg>
                Apartments in Größen von 20 m2 bis 40 m2.
              </li>
              <li className="flex items-start gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-6 w-6 shrink-0 p-1 text-red-500"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clipRule="evenodd"
                  />
                </svg>
                MINDESTBUCHUNGSZEIT 2 STD.
              </li>
            </ul>
          </div>
          <footer className="border-t border-slate-200 bg-slate-100 p-6 text-center text-sm">
            <span>Alle Preise inkl. MwSt.</span>
          </footer>
        </div>
      </div>
      {/*<!-- End Basic Pricing Table with Details --> */}
      </div>
    );
  }
}
