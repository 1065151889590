import * as React from 'react';
import CardPreise from '../../Section/Card/CardPreise';

export interface IPreiseDisposalProps {
}

export interface IPreiseDisposalState {
}

export default class PreiseDisposal extends React.Component<IPreiseDisposalProps, IPreiseDisposalState> {
  constructor(props: IPreiseDisposalProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
<div className='container'>
        <section>
          <div className="container px-6 text-center">
            <h1 className='lg:text-2xl font-bold text-gray-800 text-center my-8'>ENTSORGUNG</h1>
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="DEPONIEGE­BÜHREN"
                  price="30€"
                  description="je 100 Kg Sperrmüll (ausgenommen Sondermüll & Elektroschrott) zzgl. Arbeitszeit und Anfahrt zur Deponie"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="ZERTIFIZIERTE AKTEN­VERNICHTUNG"
                  price="48-108€"
                  description="bis 150 Kg 48€-ab 150 Kg 108€"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
              <div className="col-span-4 lg:col-span-4">
              <CardPreise
                  title="ZERTIFIZIERTE EDV VERNICHTUNG"
                  price="120€"
                  description="je 100 Kg"
                  img="../../../img/service/umzug/transport.jpg"
              />
              </div>
            </div>
  
          </div>
        </section>
          
        </div>
    );
  }
}
