import * as React from 'react';
import Banner from '../Section/Banner';
import CarouselIndicatorsControlsInside from 'Elements/Section/CarouselIndicatorsControlsInside';

export interface IFurniturePackingProps {
}

export interface IFurniturePackingState {
}

export default class FurniturePacking extends React.Component<IFurniturePackingProps, IFurniturePackingState> {
  constructor(props: IFurniturePackingProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
        <div >
        <div>
            <img
                src="../../../img/service/h-1.jpg"
                alt="Möbel bewegen"
                className="w-full h-fit object-cover mt-24 mx-auto"
            />
        </div>
        <Banner isHome={false} pageName="Möbelverpackungsservice" color="white"/>

        <div className='py-6'>

        <div className='container p-3 shadow-2xl'>
            <h2 className='
            lg:text-2xl font-bold text-gray-800 text-left my-8 border-b-4 w-fit border-black pb-2'>Möbelverpackungsservice</h2>
            <p className="text-left text-gray-700 my-4 font-bold">
            Und es ist ein Service, dessen Ziel es ist, die Möbel zu schützen und mit den besten und besten Rohstoffen zu bedecken, um sie mit Sorgfalt und Sicherheit in Verbindung zu halten.
            </p>
            <ul>
                <li className="text-gray-700 m-4"><i className="cmt-skincolor fa fa-check-square"></i>Das Unternehmen Umzugstruppe für den Umzug von Möbeln zeichnet sich durch die neuesten Mittel der Möbelverpackung aus, da es eines der führenden Unternehmen im Bereich Umzugsmöbel und Möbelverpackungen ist</li>

                <li className="text-gray-700 m-4">Wir haben professionelle Mitarbeiter auf dem Gebiet der Möbelverpackung. Wir verwenden die neuesten Möbelverpackungsmethoden. Wir verpacken alle Haushaltsgegenstände</li>
                <li className="text-gray-700 m-4">Die neuesten und besten Arten von Luftpolsterbändern, die die Möbel ohne Kratzer schützen</li>
                <li className="text-gray-700 m-4">Wir verwenden auch die neueste Methode zum Verpacken von Möbeln, nämlich Papprollen und Blasen, um die Möbel zu schützen.</li>
                <li className="text-gray-700 m-4">Wir verwenden auch die neuesten und besten Arten von Klebeband, um die Möbel zu schützen und zu verpacken</li>
            </ul>
        </div>
        <section className='container p-3 shadow-2xl'>
        <div className="mx-auto">
        <CarouselIndicatorsControlsInside images={
            [
                '../../../img/service/h-1.jpg',
                '../../../img/service/h-2.jpg',
                '../../../img/service/h-3.jpg',
                '../../../img/service/h-1.jpg',
                '../../../img/service/h-1.jpg',
 
            ]


        }/>
       
        </div>
      </section>
      <div className='container p-3 shadow-2xl'>
        <h2 className='lg:text-2xl font-bold text-gray-800 text-left my-8'>Beim Packen von Gepäck</h2>
        <ul>
            <li className="text-gray-700 m-4">Wenn Sie sich entscheiden, Ihr Gepäck einzupacken, sollten Sie diese Tipps haben, damit dies optimal durchgeführt werden kann Sortieren</li>
            <li className="text-gray-700 m-4">Sie Ihre Möbel und Habseligkeiten, teilen Sie Holz, Glas, zerbrechliche Gegenstände, Teppiche, Geschenke usw.</li>
            <li className="text-gray-700 m-4">Bereitstellung aller Materialien, die Sie für die Verpackung benötigen, je nach Art des Gepäcks</li>
            <li className="text-gray-700 m-4">Jedes Stück ist einzeln verpackt</li>
            <li className="text-gray-700 m-4">Verpacken Sie jedes Stück mit seinen eigenen Materialien. Es ist nicht korrekt, Glasstücke mit Papier zu umwickeln. Sie müssen Sprudelbeutel verwenden</li>
            <li className="text-gray-700 m-4">Sammeln Sie nach dem Verpacken jede Gruppe in einer einzigen Schachtel. Glas und Holz sollten nicht in derselben Schachtel kombiniert werden</li>
        </ul>
      </div>

        </div>
      </div>
    );
  }
}
