import * as React from "react";
import ChildTab from "./ChildTab";

export interface ITabsParentProps {}

export interface ITabsParentState {
  activeTab: number;
}

export default class TabsParent extends React.Component<
  ITabsParentProps,
  ITabsParentState
> {
  constructor(props: ITabsParentProps) {
    super(props);

    this.state = {
      activeTab: 1,
    };
  }

  handleTabClick = (tabNumber: number) => {
    this.setState({ activeTab: tabNumber });
  };

  public render() {
    return (
      <div className="">
        <div className="w-full mx-auto bg-gray-50 py-4 mt-6 lg:flex p-10 ">
          {/* Vertical Tabs */}
          <button
            className={`cursor-pointer p-4 w-full ${
              this.state.activeTab === 1 ? "bg-red-700 text-white" : ""
            }`}
            onClick={() => this.handleTabClick(1)}
          >
            ÜBERSIEDLUNGEN & UMZUGSHELFER
          </button>
          <button
            className={`cursor-pointer p-4 w-full ${
              this.state.activeTab === 2 ? "bg-red-700 text-white" : ""
            }`}
            onClick={() => this.handleTabClick(2)}
          >
            AN & ABFAHRTSKOSTEN
          </button>
          <button
            className={`cursor-pointer p-4 w-full ${
              this.state.activeTab === 3 ? "bg-red-700 text-white" : ""
            }`}
            onClick={() => this.handleTabClick(3)}
          >
            HALTEVER­BOTSZONE
          </button>
          <button
            className={`cursor-pointer p-4 w-full ${
              this.state.activeTab === 4 ? "bg-red-700 text-white" : ""
            }`}
            onClick={() => this.handleTabClick(4)}
          >
            UMZUGKARTONS
          </button>
          <button
            className={`cursor-pointer p-4 w-full ${
              this.state.activeTab === 5 ? "bg-red-700 text-white" : ""
            }`}
            onClick={() => this.handleTabClick(5)}
          >
            VERPACKUNGSMATERIAL
          </button>
          <button
            className={`cursor-pointer p-4 w-full ${
              this.state.activeTab === 6 ? "bg-red-700 text-white" : ""
            }`}
            onClick={() => this.handleTabClick(6)}
          >
            SPEZIALTRANSPORTE
          </button>
          <button
            className={`cursor-pointer p-4 w-full ${
              this.state.activeTab === 7 ? "bg-red-700 text-white" : ""
            }`}
            onClick={() => this.handleTabClick(7)}
          >
            ENTSORGUNG
          </button>
        </div>
        <div className="w-full">
          {/* Content */}
          <ChildTab activeTab={this.state.activeTab} />
        </div>
      </div>
    );
  }
}
