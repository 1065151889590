import * as React from "react";
import Home from "../Pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "../Pages/Contact";
import Services from "../Pages/Services";
import AboutUs from "../Pages/AboutUs";
import ScrollToTop from "../Section/ScrollToTop";
import GoogleReviewsComponent from "../Section/GoogleReviewsComponent";
import MoveFurniture from "../Services/MoveFurniture";
import FurniturePacking from "Elements/Services/FurniturePacking";
import MovingOfficeFurniture from "Elements/Services/MovingOfficeFurniture";
import OldFurniture from "Elements/Services/OldFurniture";
import PianoTransport from "Elements/Services/PianoTransport";
import RemovingInstallingFurniture from "Elements/Services/RemovingInstallingFurniture";
import Prices from "Elements/Pages/Prices";
import FreeOffer from "Elements/Pages/FreeOffer";
import Datenschutz from "Elements/Pages/Datenschutz";

export interface IMainProps {
}

export interface IMainState {
}

export default class Main extends React.Component<IMainProps, IMainState> {
  constructor(props: IMainProps) {
    super(props);

    this.state = {
    };
  }


  componentDidUpdate(prevProps: Readonly<IMainProps>, prevState: Readonly<IMainState>, snapshot?: any): void {

  }

  public render() {
    return (
       <div
        className="h-full lg:shadow-2xl bg-white rounded-2xl">
            <ScrollToTop />
            <Routes >
            {/*            //open home page on first load */}
            <Route path="/home" element={<Home />}  />
            <Route path="/" element={<Home />}  />
            <Route path="/contact" element={<Contact />}  />
            <Route path="/services" element={<Services />}  />
            <Route path="/aboutus" element={<AboutUs />}  />
            <Route path="/pricing" element={<GoogleReviewsComponent />}  />
            <Route path="/moveFurniture" element={<MoveFurniture />}  />
            <Route path="/furniturePacking" element={<FurniturePacking />}  />
            <Route path="/movingOfficeFurniture" element={<MovingOfficeFurniture />}  />
            <Route path="/oldFurniture" element={<OldFurniture />}  />
            <Route path="/pianoTransport" element={<PianoTransport />}  />
            <Route path="/removingInstallingFurniture" element={<RemovingInstallingFurniture />}  />
            <Route path="/prices" element={<Prices />}  />
            <Route path="/freeOffer" element={<FreeOffer />}  />
            <Route path="/datenschutz" element={<Datenschutz />}  />
            {/* Route not fuond  /> */}
            <Route path="*" element={
              <div className="container mx-auto text-center my-[200px] h-40 justify-center items-center p-10">
                <h1 className="text-4xl font-bold text-gray-800 m-auto">404</h1>
                <p className="text-gray-800">Seite nicht gefunden</p>
              </div>
            }  />
            
          </Routes> 
        </div>
    );
  }
}
