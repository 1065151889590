import React from 'react';

interface SliderIndicatorsInsideProps {
  images: string[]; // Array of image URLs
}

interface SliderIndicatorsInsideState {
  currentIndex: number; // Index of the currently displayed image
}

export default class SliderIndicatorsInside extends React.Component<SliderIndicatorsInsideProps, SliderIndicatorsInsideState> {
  private slideInterval: NodeJS.Timeout | null = null;

  constructor(props: SliderIndicatorsInsideProps) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
  }

  componentDidMount() {
    this.startSlideShow();
  }

  componentWillUnmount() {
    this.stopSlideShow();
  }

  // Starts the slideshow
  startSlideShow = () => {
    const interval = 3000; // Change slide every 3000 milliseconds (3 seconds)
    this.slideInterval = setInterval(this.nextSlide, interval);
  };

  // Stops the slideshow
  stopSlideShow = () => {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  };

  // Function to go to the next image
  nextSlide = () => {
    this.setState(prevState => ({
      currentIndex: (prevState.currentIndex + 1) % this.props.images.length,
    }));
  };

  render() {
    const { images } = this.props;
    const { currentIndex } = this.state;

    return (
      <div className="slider">
        {images.length > 0 && (
          <img
            src={images[currentIndex]}
            alt={`Slide ${currentIndex}`}
            style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }}
          />
        )}
      </div>
    );
  }
}
