import * as React from 'react';
import ServicesList from '../Section/ServicesList';
import Banner from '../Section/Banner';

export interface IRequestFormProps {
}

export interface IRequestFormState {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    message: string;    
    address: string;
    ort: string;
    plz: string;    
    succesMsg: boolean;


}

export default class RequestForm extends React.Component<IRequestFormProps, IRequestFormState> {
  constructor(props: IRequestFormProps) {
    super(props);

    this.state = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        message: '',
        address: '',
        ort: '',
        plz: '',
        succesMsg: false,
      };
        this.handleSendEmail = this.handleSendEmail.bind(this);
  }

 
  
private handleSendEmail () : void {
    const { firstName, lastName, phoneNumber, email, message, address, ort, plz ,succesMsg} = this.state;
    const data = {
        firstName,
        lastName,
        phoneNumber,
        email,
        message,
        address,
        ort,
        plz,
        succesMsg,
        };

    fetch('https://mjd.umzugstruppe.at/api/send_email_contact.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      })
      .then((response) => response.json())
      .then((data) => {
        // if success
        if(data.success){
          this.setState({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            message: '',
            address: '',
            ort: '',
            plz: '',

            succesMsg:true,
           })
        }
      }
      )
      .catch((error) => {
        console.error('Error:', error);
      });
      this.setState({ succesMsg: true });

      window.scrollTo(0, 0); 

  }


  render() {
    return (
      <div>
    <div>
        <h1 className="text-4xl text-center font-bold mt-8 uppercase">KONTAKTIEREN SIE UNS FÜR STRAHLENDE ENERGIE: WIR SIND FÜR SIE DA!</h1>
    </div>
    <form className="w-full max-w-3xl mx-auto mt-8">
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-first-name">
                        Vorname *
                    </label>
                    <input
                    onChange={e => this.setState({ firstName: e.target.value })}
                     autoComplete='off' role="presentation" className="sm:mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text"/>
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-last-name">
                        Nachname *
                    </label>
                    <input 
                    onChange={e => this.setState({ lastName: e.target.value })}
                    autoComplete='off' role="presentation" className="sm:mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white " id="grid-last-name" type="text"/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-email">
                        Email *
                    </label>
                    <input
                    onChange={e => this.setState({ email: e.target.value })}
                     autoComplete='off' role="presentation" className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white " id="grid-email" type="email"/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-password">
                        Adresse *
                    </label>
                    <input
                    onChange={e => this.setState({ address: e.target.value })}
                     autoComplete='off' role="presentation" className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white " id="grid-password" type="text"/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-phone">
                        Telefonnummer *
                    </label>
                    <input
                    onChange={e => this.setState({ phoneNumber: e.target.value })}
                     autoComplete='off' role="presentation" className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white " id="grid-phone" type="text"/>
                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-2">

                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-city">
                        ORT *
                    </label>
                    <input
                    onChange={e => this.setState({ ort: e.target.value })}
                     autoComplete='off' role="presentation" className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white " id="grid-city" type="text"/>
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-zip">
                        PLZ *
                    </label>
                    <input 
                    onChange={e => this.setState({ plz: e.target.value })}
                    autoComplete='off' role="presentation" className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white " id="grid-zip" type="text"/>
                </div>
            </div>
            <div className="md:flex md:items-center">
                <div className="w-full p-6">
                    <button
                    disabled={
                        !this.state.firstName ||
                        !this.state.lastName ||
                        !this.state.phoneNumber ||
                        !this.state.email ||
                        !this.state.address ||
                        !this.state.ort ||
                        !this.state.plz 
                    }
                    onClick={this.handleSendEmail}
                     className="shadow-lg w-full bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg
                        disabled:opacity-50 disabled:cursor-not-allowed
                     " type="button">
                    Senden 
                    </button>
                </div>
            </div>
        </form>

  
  
    </div>
    );
  }
}