import * as React from 'react';

export interface ITitelCardProps {
titel:string
description:string
image:string
}

export interface ITitelCardState {
}

export default class TitelCard extends React.Component<ITitelCardProps, ITitelCardState> {
  constructor(props: ITitelCardProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
        <div className='w-full  md:w-1/2 lg:w-1/2 xl:w-1/2 mb-3 my-6'>
        {/* icon + Titel + small p */}
        <div className="flex items-center">
          <div className="flex items-center justify-center w-1/4 lg:w-24 h-24 sm:h-32 bg-[#90110d]">
            <img src={this.props.image} alt="icon" className="  text-white"/>
          </div>
          <div className="ml-4 w-3/4">
            <h1 className="text-lg sm:text-xl font-bold text-gray-800">{this.props.titel}</h1>
            <p className="text-sm sm:text-base text-gray-700">{this.props.description}</p>
          </div>
        </div>
      </div>
    );
    }
}