import * as React from 'react';
import Banner from '../Section/Banner';

export interface IMovingOfficeFurnitureProps {
}

export interface IMovingOfficeFurnitureState {
}

export default class MovingOfficeFurniture extends React.Component<IMovingOfficeFurnitureProps, IMovingOfficeFurnitureState> {
  constructor(props: IMovingOfficeFurnitureProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
    <div className='p-3'>
        <div>
            <img
                src="../../../img/service/h-6.png"
                alt="Möbel bewegen"
                className="w-full h-fit object-cover mt-24 mx-auto"
            />
        </div>
        <Banner isHome={false} pageName="Umzug von Büromöbeln - Unternehmen" color="white"/>

        <div className='container shadow-lg p-3'>

        <div>
            <h2 className='lg:text-2xl font-bold text-gray-800 text-left my-8 border-b-4 border-black w-fit pb-2'>Moving office furniture - companies</h2>
            <p className="text-left text-gray-700 my-4 font-bold">
            Es gibt fünf Hauptphasen beim Umzug von Büromöbeln, denen Gepäcktransportunternehmen folgen:
            </p>
            <ul>
                <li className="text-gray-700 m-4">1: Die Planung und Organisation des Umzugs, die die Vorbereitung der Möbel und die Bestimmung des Zeitpunkts für den Umzug umfasst.</li>
                <li className="text-gray-700 m-4">2: Die Demontage der Möbel, die in der alten Residenz oder im alten Büro installiert sind, und die Verpackung der Möbel, um sie vor Schäden zu schützen.</li>
                <li className="text-gray-700 m-4">3: Der Transport der Möbel in die neuen Residenzen oder Büros, die die Verpackung der Möbel in die Autos des Unternehmens und den Transport der Möbel in die neuen Residenzen oder Büros umfasst.</li>
                <li className="text-gray-700 m-4">4: Die Installation der Möbel in den neuen Residenzen oder Büros, die die Installation der Möbel in den neuen Residenzen oder Büros umfasst.</li>
                <li className="text-gray-700 m-4">5: Die Reinigung der alten Residenzen oder Büros, die die Reinigung der alten Residenzen oder Büros umfasst.</li>
            </ul>
           
        </div>
              {/*<!-- Component: Two columns even layout --> */}
      <section>
      <div className="px-6 ">
          <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3">
            <div className="col-span-4 lg:col-span-1 ">
                <img             src="../../../img/service/umzug/MovingOfficeFurniture-2.jpg" alt="" />
                <img             src="../../../img/service/umzug/MovingOfficeFurniture-3.jpg" alt="" />
            </div>
            <div className="col-span-4 lg:col-span-2">
                <ul>
                    <li className="text-gray-700 m-4">Die Planung und Organisation des Umzugs umfasst die Vorbereitung der Möbel und die Bestimmung des Zeitpunkts für den Umzug.</li>
                    <li className="text-gray-700 m-4">Der Transport der Möbel in die neuen Residenzen oder Büros umfasst die Verpackung der Möbel in die Autos des Unternehmens und den Transport der Möbel in die neuen Residenzen oder Büros.</li>
                    <li className="text-gray-700 m-4">Die Installation der Möbel in den neuen Residenzen oder Büros umfasst die Installation der Möbel in den neuen Residenzen oder Büros.</li>
                    <li className="text-gray-700 m-4">Die Reinigung der alten Residenzen oder Büros umfasst die Reinigung der alten Residenzen oder Büros.</li>
                    <li className='text-gray-700 m-4'>Und es ist ein Service, dessen Ziel es ist, die Möbel zu schützen und mit den besten und besten Rohstoffen zu bedecken, um sie mit Sorgfalt und Sicherheit in Verbindung zu halten.</li>
                    <li className='text-gray-700 m-4'>Das Unternehmen Umzugstruppe für den Umzug von Möbeln zeichnet sich durch die neuesten Mittel der Möbelverpackung aus, da es eines der führenden Unternehmen im Bereich Umzugsmöbel und Möbelverpackungen ist</li>
                    <li className='text-gray-700 m-4'>Wir haben professionelle Mitarbeiter auf dem Gebiet der Möbelverpackung. Wir verwenden die neuesten Möbelverpackungsmethoden. Wir verpacken alle Haushaltsgegenstände</li>
                    <li className='text-gray-700 m-4'>Die neuesten und besten Arten von Luftpolsterbändern, die die Möbel ohne Kratzer schützen</li>
                    <li className='text-gray-700 m-4'>Wir verwenden auch die neueste Methode zum Verpacken von Möbeln, nämlich Papprollen und Blasen, um die Möbel zu schützen.</li>
                    <li className='text-gray-700 m-4'>Wir verwenden auch die neuesten und besten Arten von Klebeband, um die Möbel zu schützen und zu verpacken</li>

                </ul>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- End Two columns even layout --> */}
      <div className='container'>
            <hr className='mt-3'/>
            <h2 data-aos="fade-right" data-aos-duration="1000" className="text-3xl font-bold text-gray-800 mb-4 mt-4 text-left">Büromöbel vorbereiten</h2>
            <p className="text-gray-700 m-4">
            Die Vorbereitung der Möbel für den Umzug umfasst die Demontage der Möbel, die in der alten Residenz oder im alten Büro installiert sind, und die Verpackung der Möbel, um sie vor Schäden zu schützen.
            </p>
            <p className="text-gray-700 m-4">
            Die Demontage der Möbel umfasst die Demontage der Möbel, die in der alten Residenz oder im alten Büro installiert sind, und die Verpackung der Möbel, um sie vor Schäden zu schützen.
            </p>
            <p className="text-gray-700 m-4">
            Die Verpackung der Möbel umfasst die Verpackung der Möbel in die Autos des Unternehmens und den Transport der Möbel in die neuen Residenzen oder Büros.
            </p>
      </div>
        </div>
    </div>
    );
  }
}
