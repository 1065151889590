import * as React from 'react';

export interface IWorkstepsProps {
}

export interface IWorkstepsState {
}

export default class Worksteps extends React.Component<IWorkstepsProps, IWorkstepsState> {
  constructor(props: IWorkstepsProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
           <section>
        <div className="container px-6 m-auto items-center my-3">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className='mt-[12%] mb-auto' src="../../../img/Worksteps/w1.webp" alt="" />
            </div>
            <div className="col-span-4 lg:col-span-6  lg:h-72 bg-[#D0E0F0] rounded-lg  my-10 lg:my-10">
                <h1 className='text-4xl font-bold text-center mt-6'>Unsere Arbeitsschritte</h1>
                <p className='text-center text-[#002366] lg:mt-2 py-3 lg:py-1'>
                Starten Sie mit umzugstruppe-Umzüge sorgenfrei in ein neues Kapitel! Unser erstklassiger Umzugsservice geht weit über das bloße Transportieren von Möbeln hinaus. Wir bieten ganzheitliche Lösungen, die den Umzugsprozess von Anfang bis Ende abdecken. Unsere Profis übernehmen die fachgerechte Verpackung, den sicheren Transport und die sorgfältige Einrichtung in Ihren neuen Räumlichkeiten. Genießen Sie einen stressfreien Umzug und profitieren Sie von unserer effizienten und kosteneffektiven Dienstleistung. Wählen Sie umzugstruppe-Umzüge für einen reibungslosen Übergang!                  </p>
            </div>
            <div className="col-span-4 lg:col-span-3  h-96">
            <img className='mt-[12%] mb-auto' src="../../../img/Worksteps/w2.webp" alt="" />

            </div>

          </div>
        </div>
        <div>
            <h1 className='text-2xl lg:text-3xl font-bold text-center mt-16 lg:mt-0 xl:mt-0 '>Schritte zur Vorbereitung und Installation von Transportmodulen</h1>
            
        </div>
      </section>
         <section className='mt-6'>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work1.webp" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>1. </span>
                <span className='text-[#002366]'>Anfrage</span>
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work2.webp" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>2. </span>
                <span className='text-[#002366]'>Inspektion der Artikel</span>
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work3.webp" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>3. </span>
                <span className='text-[#002366]'>Möbel bewegen</span>
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work4.webp" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>4. </span>
                <span className='text-[#002366]'>Möbelanordnung</span>
                </p>
            </div>
          </div>
        </div>
      </section>
      </div>
    );
  }
}
