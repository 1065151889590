import * as React from 'react';
import CardPreise from '../../Section/Card/CardPreise';

export interface IPreisePrivatesTransportmittelProps {
}

export interface IPreisePrivatesTransportmittelState {
}

export default class PreisePrivatesTransportmittel extends React.Component<IPreisePrivatesTransportmittelProps, IPreisePrivatesTransportmittelState> {
  constructor(props: IPreisePrivatesTransportmittelProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className='container'>
      <section>
        <div className="container px-6 text-center">
          <h1 className='lg:text-2xl font-bold text-gray-800 text-center my-8'>SPEZIALTRANSPORTE</h1>
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-3">
            <CardPreise
                title="MIELE TARIF"
                price="125€"
                description="Transport inkl. Ab/Anschluß Nur gültig für Miele Waschmaschinen!"
                img="../../../img/service/umzug/transport.jpg"
            />
            </div>
            <div className="col-span-4 lg:col-span-3">
            <CardPreise
                title="PIANINO, KLAVIER bis 170 Kg"
                price="150€"
                description="Transport +Etagenzuschlag(25€)"
                img="../../../img/service/umzug/transport.jpg"
            />
            </div>
            <div className="col-span-4 lg:col-span-3">
            <CardPreise
                title="STUTZFLÜGEL, PIANINO"
                price="215€"
                description="Transport ab 170 Kg bis 250 Kg +Etagenzuschlag(35€)"
                img="../../../img/service/umzug/transport.jpg"
            />
            </div>
            <div className="col-span-4 lg:col-span-3">
              <CardPreise
                title="SONSTIGES"
                price="Auf Anfrage"
                description="Konzertflügel, Tresore und sonstige Schwertransporte ab 100 Kg"
                img="../../../img/service/umzug/transport.jpg"
            />
            </div>
          </div>

        </div>
      </section>
        
      </div>
    );
  }
}
